import { initInventoryHistory } from "./init";
import { thunkSpreader } from "../../../utils/helpers";
import * as thunks from "./thunks";

const thunkEnum = thunkSpreader(thunks);

export const extraReducers = {
  [initInventoryHistory.pending]: (state, action) => {
    state.init.status = "loading";
  },
  [initInventoryHistory.fulfilled]: (state, action) => {
    return {
      ...state,
      init: {
        status: "success",
      },
      ...action.payload,
    };
  },
  [initInventoryHistory.rejected]: (state, action) => {
    return {
      ...state,
      init: {
        status: "error",
      },
    };
  },
  ...thunkEnum,
};
