import { useState } from "react";
import { createFacility } from "../../../../utils/API/Facilities/Facilities";
import { createPreflightCheck } from "../API/preflight";
import { Grid, useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import { naturalSort } from "../../../../utils/naturalSort";
import { useSelector } from "react-redux";
import { validateLat, validateLong } from "../utils/utils";
import MaterialUiButton from "../../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleSelect from "../../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../../components/Forms/FieldTypes/TextField";
import StateSelect from "../../../../components/Selects/StateSelect";


export default function CreateFacility(props) {

  const theme = useTheme();

  const classes = {
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  }

  const {
    apiUrl,
    dispatchGlobal,
    locationTypes = [],
    notificationModal,
    onHide,
    token,
    updateFacilities,
  } = props;

  const defaultFacilityRadius = useSelector(
    (state) =>
      state.organization.organization.propertiesMap?.defaultFacilityRadius
  );

  const [createState, setCreateState] = useState({
    ...props.facility,
    error: {},
    latValid: true,
    longValid: true,
    validated: false,
  });
  const {
    facilityType = "",
    error = {
      address1: false,
      city: false,
      facilityType: false,
      latitude: false,
      longitude: false,
      name: false,
      postalCode: false,
      state: false,
    },
    location = {},
    name = "",
    propertiesMap = {},
  } = createState;

  const {
    address1,
    city = "",
    postalCode = "",
    state = "",
    latitude,
    longitude,
  } = location;
  const { locationDetails = "" } = propertiesMap;
  const facilityTypeSelectOptions = [...locationTypes]
    .sort((a, b) => naturalSort(a, b))
    .map((element, index) => {
      return { label: element, value: element };
    });

  const handleSubmit = () => {
    const { location, facilityType, name, propertiesMap } = createState;
    let body = {
      location,
      facilityType,
      name,
      propertiesMap,
      radius: defaultFacilityRadius / 1000 || 0.1,
    };
    const centroidString =
      location.latitude && location.longitude
        ? `POINT(${location.longitude} ${location.latitude})`
        : null;

    createPreflightCheck(
      { apiUrl, token },
      {
        name: name,
        centroidString: centroidString,
        radius: defaultFacilityRadius / 1000 || 0.1,
        location: location,
      }
    ).then((res) => {
      if (res.error) {
        notificationModal(res.error, true);
      } else if (res.success) {
        createFacility(props, body).then((response) => {
          if (response.success) {
            onHide();
            notificationModal("Facility Successfully Created", false);
            dispatchGlobal(
              updateFacilities({
                body: response.facility,
                facilityId: response.facility?.facilityId,
              })
            );
            // onSuccess();
          } else {
            notificationModal(`${response.error}`, true);
          }
        });
      }
    });

    setCreateState((prevState) => ({ ...prevState, validated: true }));
  };

  function validateFields() {
    let isValidated = true;
    let fieldsToValidate = [
      { id: "address1", value: address1 },
      { id: "city", value: city },
      { id: "name", value: name },
      { id: "postalCode", value: postalCode },
      { id: "state", value: state },
    ];

    // We dont need to validate lat/lon if the user has entered in the
    // city/state/postalCode
    if (isEmpty(city) || isEmpty(state) || isEmpty(postalCode)) {
      fieldsToValidate.concat([
        { id: "latitude", value: latitude },
        { id: "longitude", value: longitude },
      ]);
    }

    // We validate the fields
    fieldsToValidate.forEach((element) => {
      const { id, value = "" } = element;

      if (value?.length === 0) {
        isValidated = false;

        setCreateState((prevState) => ({
          ...prevState,
          error: { ...prevState.error, [id]: true },
        }));
      }
    });

    // If nothing has been tripped, we move forward to the onSubmit
    if (isValidated) {
      handleSubmit();
    }
  }

  return (
    <Grid sx={classes.root} container spacing={2}>
      {/* Facility Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={error?.name}
          id="Facility Name"
          inputProps={{ "data-cypress-id": "facilities-create-facility-txt-facility-name" }}
          label="Facility Name"
          onChange={(event) => {
            setCreateState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, name: false },
              name: event.target.value,
            }));
          }}
          required
          value={name}
        />
      </Grid>

      {/* Facility Type */}
      <Grid item xs={6}>
        <SimpleSelect
          error={error?.facilityType}
          id="Facility Type"
          inputProps={{ "data-cypress-id": "facilities-create-facility-select-facility-type" }}
          label="Facility Type"
          onChange={(event) => {
            setCreateState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, facilityType: false },
              facilityType: event.target.value,
            }));
          }}
          options={facilityTypeSelectOptions}
          value={facilityType}
          variant="outlined"
        />
      </Grid>

      {/* Address */}
      <Grid item xs={12}>
        <SimpleTextField
          error={error?.address1}
          errorText="Required Field if No Lat/Long Set"
          id="Address"
          inputProps={{ "data-cypress-id": "facilities-create-facility-txt-address" }}
          label="Address"
          onChange={(event) => {
            let newAddress = createState.location || {};
            newAddress.address1 = event.target.value;
            setCreateState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, address1: false },
              location: newAddress,
            }));
          }}
          required={location.latitude || location.longitude ? false : true}
          value={address1}
        />
      </Grid>

      {/* City */}
      <Grid item xs={6}>
        <SimpleTextField
          error={location.latitude || location.longitude ? false : error?.city}
          errorText="Required Field if No Lat/Long Set"
          id="City"
          inputProps={{ "data-cypress-id": "facilities-create-facility-txt-city" }}
          label="City"
          onChange={(event) => {
            let newAddress = createState.location || {};
            newAddress.city = event.target.value;
            setCreateState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, city: false },
              location: newAddress,
            }));
          }}
          required={location.latitude || location.longitude ? false : true}
          value={city}
        />
      </Grid>

      {/* State */}
      <Grid item xs={6}>
        <StateSelect
          inputProps={{ "data-cypress-id": "facilities-create-facility-select-state" }}
          error={location.latitude || location.longitude ? false : error?.state}
          errorText="Required Field if No Lat/Long Set"
          onChange={(state) => {
            let newAddress = createState.location || {};
            newAddress.state = state;
            setCreateState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, state: false },
              location: newAddress,
            }));
          }}
          required={location.latitude || location.longitude ? false : true}
          requiredFieldMessage="Required Field if no Lat/Long set"
          state={state}
        />
      </Grid>

      {/* Zip Code */}
      <Grid item xs={6}>
        <SimpleTextField
          error={
            location.latitude || location.longitude ? false : error?.postalCode
          }
          errorText="Required Field if No Lat/Long Set"
          id="Zip Code"
          inputProps={{ "data-cypress-id": "facilities-create-facility-txt-zip-code" }}
          label="Zip Code"
          onChange={(event) => {
            let newAddress = createState.location || {};
            newAddress.postalCode = event.target.value;
            setCreateState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, postalCode: false },
              location: newAddress,
            }));
          }}
          required={location.latitude || location.longitude ? false : true}
          value={postalCode}
        />
      </Grid>

      {/* Latitude */}
      <Grid item xs={6}>
        <SimpleTextField
          error={error?.latitude}
          errorText="Please enter a properly formatted latitude"
          id="Latitude"
          inputProps={{ "data-cypress-id": "facilities-create-facility-txt-latitude" }}
          label="Latitude"
          required={
            location.latitude?.length > 0 || location.longitude?.length > 0
              ? true
              : false
          }
          onChange={(event) => {
            const hasLength = event.target.value?.length > 0;

            const latValid = hasLength
              ? validateLat.test(parseFloat(event.target.value).toFixed(5))
              : true;

            setCreateState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, latitude: !latValid },
              location: {
                ...prevState.location,
                latitude: hasLength ? event.target.value : null,
                lat1000: hasLength ? parseInt(event.target.value) * 1000 : null,
              },
              latValid: latValid,
            }));
          }}
          value={latitude}
        />
      </Grid>

      {/* Longitude */}
      <Grid item xs={6}>
        <SimpleTextField
          error={error?.longitude}
          errorText="Please enter a properly formatted latitude"
          id="Longitude"
          inputProps={{ "data-cypress-id": "facilities-create-facility-txt-longitude" }}
          label="Longitude"
          required={
            location.latitude?.length > 0 || location.longitude?.length > 0
              ? true
              : false
          }
          onChange={(event) => {
            const hasLength = event.target.value?.length > 0;
            const longValid = hasLength
              ? validateLong.test(parseFloat(event.target.value).toFixed(5))
              : true;

            setCreateState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, longitude: !longValid },
              location: {
                ...prevState.location,
                longitude: hasLength ? event.target.value : null,
                lon1000: hasLength ? parseInt(event.target.value) * 1000 : null,
              },
              longValid: longValid,
            }));
          }}
          value={longitude}
        />
      </Grid>

      {/* Facility Details */}
      <Grid item xs={12}>
        <SimpleTextField
          error={error?.locationDetails}
          id="Facility Details"
          inputProps={{ "data-cypress-id": "facilities-create-facility-txt-facility-details" }}
          label="Facility Details - optional"
          multiline
          name="locationDetails"
          onChange={(event) => {
            let newPropertiesMap = createState.propertiesMap || {};
            newPropertiesMap.locationDetails = event.target.value;
            setCreateState((prevState) => ({
              ...prevState,
              propertiesMap: newPropertiesMap,
            }));
          }}
          rows={4}
          value={locationDetails}
        />
      </Grid>

      {/* Cancel and Submit Buttons */}
      <Grid sx={classes.buttonContainer} item xs={12}>
        {/* Cancel Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="cancel"
            cypressId="facilities-create-facility-btn-cancel"
            fullWidth={true}
            label="Cancel"
            onClick={() => onHide()}
            variant="outlined"
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="submit"
            cypressId="facilities-create-facility-btn-submit"
            fullWidth={true}
            label="Submit"
            onClick={() => validateFields()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
