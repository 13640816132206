import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const { primary = {}, success = {}, third = {} } = palette;

  console.log(theme)

  return createStyles({
    bottomPush: {
      height: "100px",
    },
    drawer: (props) => ({
      flexShrink: 0,
      width: props.sideNavWidth,
    }),
    drawerPaper: (props) => ({
      backgroundColor: `${primary?.main}`,
      width: props.sideNavWidth,
    }),
    listItem: {
      justifyContent: "center",
      "& *": {
        color: "#fefefe",
      },
      "&:focus-visible": {
        backgroundColor: `${success?.main}`,
      },
      "&:hover": {
        "& *": {
          color: "white!important",
        },
        backgroundColor: `${success?.main}`,
      },
    },
    listItemActive: {
      backgroundColor: `${success?.main}`,
      "& *": {
        color: "white",
      },
    },
    listItemIcon: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    listItemText: {
      fontSize: "0.7em",
      textAlign: "center",
    },
    menuIcon: (props) => ({
      height: props.appBarHeight,
      justifyContent: "center",
    }),
    menuItem: {
      backgroundColor: `${third?.main}`,
      color: "white",
      "&:hover": {
        color: "white",
        backgroundColor: `${primary?.main}`,
      },
    },
    menuList: {
      justifyContent: "center",
      paddingBottom: "0!important",
      paddingTop: "0!important",
    },
    menuPaper: {
      backgroundColor: `${third?.main}`,
    },
    userBadgeItem: {
      backgroundColor: `${primary?.main}`,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      position: 'sticky',
      "&:hover": {
        backgroundColor: `${success?.main}`,
      },
    },
  })
}

);
