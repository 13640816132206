import { createAsyncThunk } from "@reduxjs/toolkit";

export const initDevices = createAsyncThunk(
  "devices/init",
  async (_, thunkAPI) => {
    const { getState, extra: apiService, dispatch } = thunkAPI;
    // expected user to be initialized before initializing organization
    const {
      token = "",
      organizationId = "",
      userPropertiesMap = {},
    } = getState().user;

    const { deviceTableSettings = { defaultColumnOrder: [] } } =
      userPropertiesMap?.propertiesMap;

    const data = await apiService.devices
      .searchDevices({ token, organizationId }, deviceTableSettings)
      .then(async (data) => {
        if (data.error) {
          return dispatch({
            type: "alert/showAlert",
            payload: { type: "error", text: data.error },
          });
        } else if (data.success) {
          const deviceAssetsMap = await apiService.devices.buildDeviceAssetsMap(
            { token, organizationId },
            data.assets || []
          );
          return {
            success: true,
            filters: deviceTableSettings,
            devices: data.assets,
            deviceAssetsMap: deviceAssetsMap,
            count: data.count,
          };
        } else {
          return dispatch({
            type: "alert/showAlert",
            payload: { type: "error", text: data.error },
          });
        }
      });

    data.reduxRequestId = thunkAPI.requestId;
    return data;
  }
);
