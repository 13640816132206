import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { Redirect } from "react-router-dom";
import MaterialUiButton from "../../Buttons/MaterialUiButton/MaterialUiButton";

export default function GenerateCQRContent(props) {

  const classes = {
    inputContainer: {
      display: "flex",
      justifyContent: "space-around",
      padding: "1.5rem 0",
    },
    label: {
      paddingTop: "1.5rem",
      textAlign: "center",
    },
  }

  const {
    asset = {},
    closeModal,
    inventoryItem = {},
    setGenerateCQR,
  } = props;

  const assetId = asset.assetId;
  const assetTag = asset.tag;
  const inventoryItemId = inventoryItem.assetId;
  const inventoryItemTag = inventoryItem.tag;

  const [isRedirect, setIsRedirect] = useState(false);
  return (
    <>
      {isRedirect ? (
        <Redirect
          to={{
            pathname: `/generateCQR/`,
            state: {
              selectedAssets: {
                [assetId ? assetId : inventoryItemId]: assetId
                  ? asset
                  : inventoryItem,
              },
            },
          }}
        />
      ) : null}
      <DialogContent sx={classes.label}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Would you like to generate a CQR for{" "}
              {assetTag ? assetTag : inventoryItemTag}?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={classes.inputContainer}>
        <MaterialUiButton
          color="cancel"
          label="No"
          onClick={() => {
            closeModal();
            setTimeout(() => {
              setGenerateCQR(false);

              // This is a hack until we work on clearing out select fields when the asset is created on the assetContent component
              window.location.reload(false);
            }, 500);
          }}
          variant="contained"
        />
        <MaterialUiButton
          autoFocus
          color="primary"
          label="Yes"
          onClick={() => {
            setIsRedirect(true);
            setTimeout(() => {
              closeModal();
            }, 200);
          }}
          variant="contained"
        />
      </DialogActions>
    </>
  );
}
