import { Box, Typography } from "@mui/material";
import { ReactComponent as CaretUpSolidIcon } from "../../../assets/faIcons/caret-up-solid.svg";
import CircleIcon from "@mui/icons-material/Circle";
import MuiSVGIcon from "../../../components/IconPicker/MuiSVGIcon";
import React from "react";
import SquareIcon from "@mui/icons-material/Square";

function MapViewLegend(props) {
  const { state, setState } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: "rgba(0, 0, 0, 0.54)",
        fontWeight: 700,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Assets: </Typography>
        <Typography aria-label="green triangle" component="span">
          <MuiSVGIcon
            icon={<CaretUpSolidIcon/>}
            sx={{
              color: "green",
              fontSize: "1.5rem",
              marginRight: "1rem",
            }}
          />
        </Typography>
      </Box>
      {/* Box for radios */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Radios: </Typography>
        <Typography aria-label="black square" component="span">
          <SquareIcon
            sx={{
              color: "black",
              marginRight: "1rem",
            }}
          />
        </Typography>
      </Box>
      {/* Box for target zones: */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Target Zones: </Typography>
        <Typography aria-label="blue circle" component="span">
          <CircleIcon
            sx={{
              color: "#947447",
              marginRight: "1rem",
            }}
          />
        </Typography>
      </Box>
      {/* Box for processing zones: */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Processing Zones: </Typography>
        <Typography aria-label="red circle" component="span">
          <CircleIcon
            sx={{
              color: "red",
              marginRight: "1rem",
            }}
          />
        </Typography>
      </Box>
      {/* box for processing zones(highlighted): */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Processing Zones(highlighted): </Typography>
        <Typography aria-label="purple circle" component="span">
          <CircleIcon
            sx={{
              color: "purple",
              marginRight: "1rem",
            }}
          />
        </Typography>
      </Box>
      {/* BOx for target zones(highlighted): */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Target Zones(highlighted): </Typography>
        <Typography aria-label="orange circle" component="span">
          <CircleIcon
            sx={{
              color: "#03f8fc",
              marginRight: "1rem",
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}

export default MapViewLegend;
