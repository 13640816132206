import { formatLatLon } from "../../../../utils/lat_lon_utils";
import { isEmpty } from "lodash";
import { parseUserInfo } from "../utils";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useTranslation as UseTranslation } from "react-i18next"; // "useTranslation is neither a React function component nor a custom React Hook function. React component names must start with an uppercase letter"
import getColumnType from "../../../../utils/get_column_type";
import modifyColumns from "../../../../utils/modifyColumns";

export default function buildColumns(props) {
  const {
    appUserType,
    classifications,
    columnOrder,
    customs,
    facilities,
    history,
    parentAssetIdTagMap = {},
    timeZone,
    zones,
  } = props;
  const showPCA = ![
    "Asset-Operations",
    "Asset",
    "Warehouse-Operations",
  ].includes(appUserType);

  const { t } = UseTranslation();

  const classificationColumns = !isEmpty(classifications)
    ? Object.keys(classifications).map((classKey) => {
        const { name } = classifications[classKey];
        return {
          field: name,
          headerName: name,
          renderCell: (data) => {
            if (data.rowNode.type === "group") return data.value;
            const { row, tabIndex } = data;
            const { classificationMap = {} } = row;

            const classificationValue = Object.keys(classificationMap).filter(
              (k) => k === name
            )[0];

            return (
              <div
                className="pointer"
                key={tabIndex}
                onClick={() => onClick(row)}
              >
                {classificationMap[classificationValue] || t("dnpinput1")}
              </div>
            );
          },
          valueGetter: (value, row) => {
            const { classificationMap = {} } = row;

            const classificationValue = Object.keys(classificationMap).filter(
              (k) => k === name
            )[0];

            return classificationMap[classificationValue] || t("dnpinput1");
          },
          groupingValueGetter: (value, row) => {
            const { classificationMap = {} } = row;

            const classificationValue = Object.keys(classificationMap).filter(
              (k) => k === name
            )[0];

            return classificationMap[classificationValue] || null;
          },
        };
      })
    : [];

  const customColumns = !isEmpty(customs)
    ? customs.map((item) => {
        const { customId = "", dataType, name = "" } = item;
        const colType = getColumnType(dataType);
        return {
          field: name,
          headerName: name,
          id: "name",
          renderCell: (data) => {
            // Check if the row type is "group" or "pinnedRow", and return formatted date or fallback text accordingly.
            if (["group", "pinnedRow"].includes(data.rowNode.type)) {
              return colType === "date" && data.value
                ? new Date(data.value).toLocaleDateString() || t("dnpinput1")
                : data.value || t("dnpinput1");
            }
            const { row, tabIndex } = data;
            const { customMap = {} } = row;

            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];
            const renderValue = customMap[customValue];

            return (
              <div
                className="pointer"
                key={`${tabIndex}-${customId}}`}
                onClick={() => onClick(row)}
              >
                {/* //if colType is date and returnVal is not undefined, return the date */}
                {colType === "date" && renderValue !== undefined
                  ? new Date(renderValue).toLocaleDateString()
                  : renderValue || t("dnpinput1")}
              </div>
            );
          },
          type: colType,
          valueGetter: (value, row) => {
            const { customMap = {} } = row;

            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];
            const returnVal = customMap[customValue];
            if (colType === "number") {
              return parseInt(returnVal) || null;
            } else if (colType === "date") {
              return new Date(returnVal) || null;
            }
            if (colType === "date") {
              return returnVal !== undefined ? new Date(returnVal) : null;
            }
            return returnVal || null;
          },
          groupingValueGetter: (value, row) => {
            const { customMap = {} } = row;

            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];
            const returnVal = customMap[customValue];
            if (colType === "number") {
              return parseInt(returnVal) || null;
            } else if (colType === "date") {
              return returnVal !== undefined ? new Date(returnVal) : null;
            }
            return returnVal || null;
          },
        };
      })
    : [];

  const historyTableColumns = [
    // timeOfLog
    {
      field: "timeOfLog",
      groupable: false,
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { row } = data;
        const { timeOfLogLong } = row;
        return (
          <div className="pointer" onClick={() => onClick(row)}>
            {timeOfLogLong
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (value, row) => {
        const timeOfLogLong = row.timeOfLogLong;
        return timeOfLogLong
          ? new Date(timeOfLogLong)
          : //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
            new Date(8640000000000000);
      },
    },
    // parentId
    {
      field: "parentId",
      headerName: "Parent Asset Tag",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { parentId = "" } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {parentAssetIdTagMap[parentId]
              ? parentAssetIdTagMap[parentId]
              : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { parentId = "" } = row;
        return parentAssetIdTagMap[parentId]
          ? parentAssetIdTagMap[parentId]
          : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { parentId = "" } = row;
        return parentAssetIdTagMap[parentId]
          ? parentAssetIdTagMap[parentId]
          : null;
      },
    },
    // assetTag
    {
      field: "assetTag",
      headerName: "Inventory Tag",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { assetTag } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {assetTag ? assetTag : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { assetTag } = row;
        return assetTag ? assetTag : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { assetTag } = row;
        return assetTag ? assetTag : null;
      },
    },
    // assemblyId
    {
      field: "assemblyId",
      headerName: "Ticket Id",
      id: "assemblyId",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { assemblyName } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {assemblyName ? assemblyName : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.assemblyName || t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        return row.assemblyName || null;
      },
    },
    // category
    {
      field: "category",
      headerName: "Inventory Category",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { category } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {category ? category : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { category } = row;
        return category ? category : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { category } = row;
        return category ? category : t("dnpinput1");
      },
    },
    // event
    {
      field: "event",
      headerName: "Event",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { event } = row;
        return (
          <div
            className="pointer"
            key={tabIndex}
            style={{
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => onClick(row)}
          >
            {event ? event : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { event } = row;
        return event ? event : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { event } = row;
        return event ? event : null;
      },
    },
    // user
    {
      field: "user",
      headerName: "User",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const userInfo = parseUserInfo(row);
        return (
          <div
            className="pointer"
            key={tabIndex}
            style={{
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => onClick(row)}
          >
            {userInfo || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => parseUserInfo(row),
      groupingValueGetter: (value, row) => parseUserInfo(row) || null,
    },
    // facility
    {
      field: "facility",
      headerName: "Facility Name",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { facility, facilityId, latitude, longitude } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {facility
              ? facility
              : !latitude || !longitude
              ? t("dnpinput1")
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId]?.name
              ? facilities[facilityId]?.name
              : "Not Registered"}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { facility, facilityId, latitude, longitude } = row;

        return facility
          ? facility
          : !latitude || !longitude
          ? t("dnpinput1")
          : facilityId && facilities[facilityId] && facilities[facilityId]?.name
          ? facilities[facilityId]?.name
          : "Not Registered";
      },
      groupingValueGetter: (value, row) => {
        const { facility, facilityId, latitude, longitude } = row;

        return facility
          ? facility
          : !latitude || !longitude
          ? t("dnpinput1")
          : facilityId && facilities[facilityId] && facilities[facilityId]?.name
          ? facilities[facilityId]?.name
          : "Not Registered";
      },
    },
    // latLong
    {
      field: "latLong",
      headerName: "Latitude, Longitude",
      id: "latLong",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { latitude, longitude, propertiesMap } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return (
          <div
            onClick={() => onClick(row)}
            // className={classes.columnHeader}
            key={row.assetId}
          >
            {formattedLatLong}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return formattedLatLong;
      },
      groupingValueGetter: (value, row) => {
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return formattedLatLong || null;
      },
    },
    // zone
    {
      field: "zone",
      headerName: "Zone",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {/* Show pZoneName. If not present show pZoneName. If not present the Zone Name */}
            {pZoneName
              ? pZoneName
              : zoneName
              ? zoneName
              : zoneId && zones[zoneId]
              ? zones[zoneId].name
              : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { zone = {} } = row;
        const { pZoneName = "", zoneId = "" } = zone;

        return pZoneName
          ? pZoneName
          : zoneId && zones[zoneId]
          ? zones[zoneId].name
          : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { zone = {} } = row;
        const { pZoneName = "", zoneId = "" } = zone;

        return pZoneName
          ? pZoneName
          : zoneId && zones[zoneId]
          ? zones[zoneId].name
          : null;
      },
    },
    // binLocation
    {
      field: "binLocation",
      headerName: "Bin Location",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { zone = {} } = row;
        const { binLocation = "" } = zone;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              row.zone
                ? binLocation
                : row.binLocation
                ? row.binLocation
                : row.propertiesMap && row.propertiesMap.binLocation
                ? row.propertiesMap.binLocation
                : t("dnpinput1")
            }
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { zone = {} } = row;
        const { binLocation = "" } = zone;
        return row.zone
          ? binLocation
          : row.binLocation
          ? row.binLocation
          : row.propertiesMap && row.propertiesMap.binLocation
          ? row.propertiesMap.binLocation
          : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { zone = {} } = row;
        const { binLocation = "" } = zone;
        return row.zone
          ? binLocation
          : row.binLocation
          ? row.binLocation
          : row.propertiesMap && row.propertiesMap.binLocation
          ? row.propertiesMap.binLocation
          : null;
      },
    },
    // state
    {
      field: "state",
      headerName: "State",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row, tabIndex } = data;
        const { state = t("dnpinput1") } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {state ? state : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { state = t("dnpinput1") } = row;
        return state ? state : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { state } = row;
        return state ? state : null;
      },
    },
    // quantityCurrent
    {
      field: "quantityCurrent",
      headerName: "Quantity Qty",
      renderCell: (data) => {
        if (data.rowNode.type === "group" || data.rowNode.type === "pinnedRow")
          return data.value;
        const { row, tabIndex } = data;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {row.quantity?.quantityCurrent || t("dnpinput1")}
          </div>
        );
      },
      type: "number",
      valueGetter: (value, row) => {
        if (row.quantity?.quantityCurrent) {
          return parseInt(row.quantity.quantityCurrent);
        } else {
          return null;
        }
      },
      groupingValueGetter: (value, row) => {
        if (row.quantity?.quantityCurrent) {
          return parseInt(row.quantity.quantityCurrent);
        } else {
          return null;
        }
      },
    },
  ]
    .filter((col) => {
      if (!showPCA) {
        return col.id !== "flagged";
      } else {
        return true;
      }
    })
    .concat(classificationColumns)
    .filter((col) => {
      // get rid of event specific comments
      if (col.field.includes("Comments") && col.field !== "Comments") {
        return false;
      } else {
        return true;
      }
    })
    // TODO - Elastic Wait for this to get refactored on AC4
    .concat(customColumns);

  //iterate over columns and if a column has a type of number, allow aggregation on that column
  historyTableColumns.forEach((column) => {
    if (column.type === "number") {
      column.aggregable = true;
    } else {
      column.aggregable = false;
    }
  });

  const onClick = (row) => {
    const { assetId = "" } = row;
    if (assetId) {
      const url = `/inventorySnapshot/${assetId}`;
      history.push(url);
    }
  };

  // Here we need to go through the columnOrder and handle Sorting / Renaming / Visibility settings
  if (columnOrder) {
    return modifyColumns({ columnOrder, historyTableColumns });
  }

  return { historyTableColumns };
}
