const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export async function searchDevices(props, filters, start = 0, limit = 300) {
  const { token, organizationId } = props;
  const { asset_type = [], last_event = [], status = [], tags = [] } = filters;
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            terms: {
              asset_mode: ["Device"],
            },
          }
        ],
      },
    },
    limit: limit,
    sort: [
      {
        time_of_log: "asc",
      },
      {
        tag: "desc",
      },
    ],
    page: start,
  };

  // Events - TODO - Elastic - I dont think last_event is ever hit anywhere on the console. Need to create a ticket to look into this
  if (last_event && last_event.length > 0) {
    let lastEventsArray = last_event.map((event) => event);

    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        last_event: lastEventsArray,
      },
    });
  }

  // Tags
  if (tags && tags.length) {
    let tagArray = tags.map((e) => {
      return e.value;
    });

    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        tag: tagArray,
      },
    });
  }

  // Asset Type
  if (asset_type && asset_type.length > 0) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        asset_type,
      },
    });
  }

  // Status
  if (status?.length) {
    const statusArray = status.map((stat) => ({
      match: { "device.status": stat.value },
    }));

    elasticQuery.elasticSearchQuery.bool.must.push({
      nested: {
        path: "device",
        query: {
          bool: {
            should: statusArray,
          },
        },
      },
    });
  }

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
}

export async function searchDeviceAssets(
  props,
  joinedDeviceAssetIds = [],
  start = 0,
  limit = 300
) {
  const { token, organizationId } = props;
  const elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            terms: {
              parent_id: joinedDeviceAssetIds,
            },
          },
        ],
        minimum_should_match: 1,
      },
    },
    sort: [
      {
        time_of_log: "asc",
      },
      {
        tag: "desc",
      },
    ],
    limit: limit,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
}

export async function buildDeviceAssetsMap(props, deviceList) {
  const deviceAssetIds =
    deviceList.length > 0
      ? deviceList
          .map((d) => {
            if (d.assetId) {
              return d.assetId;
            }
            return null;
          })
          .filter((el) => el !== null)
      : null;

  if (deviceAssetIds) {
    const deviceAssetsMap = await searchDeviceAssets(
      props,
      deviceAssetIds
    ).then((res) => {
      if (res.error) {
        return { error: "Error fetching device assets." };
      }

      let deviceAssetsMap = {};
      res.assets.forEach((ass) => {
        if (!deviceAssetsMap[ass.parentId]) {
          deviceAssetsMap[ass.parentId] = [];
        }

        deviceAssetsMap[ass.parentId].push(ass);
      }, {});

      return {
        ...deviceAssetsMap,
      };
    });
    return deviceAssetsMap;
  }

  return null;
}

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      deviceTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const devices = {
  searchDevices,
  buildDeviceAssetsMap,
  searchDeviceAssets,
  saveFilterSettings,
};
