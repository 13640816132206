import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function SimpleCheckbox(props) {

  const classes = {
    checkbox: {
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight:700,
    },
  }

  const {
    checked = false,
    color = "primary",
    inputProps,
    label = "",
    name = "",
    onChange,
  } = props;
  return (
    <FormControlLabel
      sx={classes.checkbox}
      control={
        <Checkbox
          checked={checked}
          inputProps={inputProps}
          onChange={(e) => {
            onChange(e);
          }}
          name={name}
          color={color}
        />
      }
      label={label}
    />
  );
}

export default SimpleCheckbox;
