import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";

export default function RecursiveTreeView(props) {
  const classes = {
    root: {
      flexGrow: 1,
    },
    label: {
      fontFamily: "Lato",
      fontWeight: 500,
      fontSize: "1rem",
    },
  };

  const data = React.useMemo(() => props.data, [props.data]);

  const renderTree = (nodes) => (
    <TreeItem
      classes={{ label: classes.label }}
      itemId={nodes.id}
      key={nodes.id}
      label={nodes.label}
    >
      {Array.isArray(nodes.children) && nodes.children.length > 0
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <SimpleTreeView
      sx={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={data?.defaultExpanded || ["root"]}
      defaultSelected={data?.defaultSelected || "root"}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={(e, value) => {}}
    >
      {data ? renderTree(data) : null}
    </SimpleTreeView>
  );
}
