import { useEffect, useMemo } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { Grid, Typography } from "@mui/material";
import { utc_to_local_12_hour_clock } from "../../../utils/date_time_utils";
import GoogleMapComponent from "../GoogleMapComponent";
import mapboxgl from "mapbox-gl";
import moment from "moment";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function Map(props) {
  const {
    eventTypesMap,
    facilities,
    mapSettings = {},
    setMapSettings = () => { },
    state = {},
    timeZone,
  } = props;
  const { assetHistories = [], histories: historiesFromProps = [], trips = [] } = state;

  // I hate that we have this here. It should just be coming from one source.
  // But this got goofed at some point. So im making a catch for it for now.
  const historiesSource =
    assetHistories.length !== 0 ? assetHistories : historiesFromProps;

  const histories = useMemo(
    () => historiesSource.histories || [],
    [historiesSource.histories]
  );

  const renderMarkers = histories
    .map((event, idx) => {
      // If the current event does not have a lat/lon, we check to see if a facilityId exist.
      // If so, we use the facilityId.location.lat and lon. Otherwise we use the event.lat and lon
      if (
        event &&
        (event.latitude !== 0 || event.longitude !== 0) &&
        event.latitude &&
        event.longitude
      ) {
        return event;
      } else if (
        event &&
        (event.latitude !== 0 || event.longitude !== 0) &&
        event.facilityId
      ) {
        const currentFacility = facilities[event.facilityId] || {};
        const { location = {} } = currentFacility;
        const { latitude, longitude } = location;
        return { ...event, latitude, longitude };
      } else {
        return null;
      }
    })
    .filter((event) => event !== null)
    .sort((a, b) => a.timeOfLog - b.timeOfLog)


  const popUpContent = (marker) => {
    const {
      event = null,
      facilityId = null,
      latitude = 0,
      longitude = 0,
      propertiesMap = null,
      timeOfLog = null,
    } = marker
    return (
      <Grid container>
        {/* Event */}
        <Grid item xs={12}>
          {event ? <Typography component="h2" variant="h6">{event}</Typography> : ""}
        </Grid>

        {/* Time of Log */}
        <Grid item xs={12}>
          {timeOfLog ? <span>Date: {utc_to_local_12_hour_clock(timeOfLog, timeZone)}</span> : ""}
        </Grid>

        {/* Facility */}
        <Grid item xs={12}>
          {facilityId && facilities[facilityId] && latitude !== 0 && longitude !== 0 ?
            <span>
              Facility: {facilities[facilityId].name}
            </span> : ""}
        </Grid>

        {/* Note */}
        <Grid item xs={12}>
          {propertiesMap && propertiesMap.note ?
            <span>Note: {propertiesMap.note}</span> : ""}
        </Grid>
      </Grid>)
  }

  const polylinePopUpContent = (polyline) => {
    const { data = {} } = polyline
    const { label = '', path = [] } = data
    const tripStart = path[0].timeOfLogLong ? moment(path[0].timeOfLogLong).format("MM/DD/YYYY hh:mm A") : ''
    const tripEnd = path[path.length - 1].timeOfLogLong ? moment(path[path.length - 1].timeOfLogLong).format("MM/DD/YYYY hh:mm A") : ''
    const duration = path.length > 0 ? moment.duration(path[path.length - 1].timeOfLogLong - path[0].timeOfLogLong).humanize() : ''

    return (
      <Grid container>

        {/* Label */}
        <Grid item xs={12}>
          <Typography variant="h6">{label}</Typography>
        </Grid>

        {/* From */}
        <Grid item sx={{ textAlign: 'left' }} xs={12}>
          <Typography component="p" variant="h6">Trip Start: </Typography>
          <Typography variant="subtitle1"> {tripStart} </Typography>
        </Grid>

        {/* To */}
        <Grid item sx={{ textAlign: 'left' }} xs={12}>
          <Typography component="p" variant="h6">Trip End:</Typography>
          <Typography variant="subtitle1"> {tripEnd} </Typography>
        </Grid>

        {/* Trip Duration */}
        <Grid item sx={{ textAlign: 'left' }} xs={12}>
          <Typography component="p" variant="h6">Trip Duration:</Typography>
          <Typography variant="subtitle1"> {duration} </Typography>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    setMapSettings({ center: { lat: 34.561187, lng: -40.814914 }, zoom: 2.3 })
  }, [setMapSettings])

  useEffect(() => {
    const defaultLat = 60.363007;
    const defaultLon = -43.63390;
    let firstItemInHistoriesWithAFacility = histories.find(
      (element) => element.facilityId && !element.longitude && !element.latitude
    );
    let firstItemInHistoriesWithALatLon = histories.find(
      (element) => element.latitude && element.longitude
    );

    // If the first item in the array does not have a lat/lon but does have a facility id
    // Otherwise check to see if the first item has valid lat/lon values. If there are no lat/lon values to use
    // we default the user to Austin, Tx.
    if (histories && firstItemInHistoriesWithAFacility) {
      const currentFacility =
        facilities[firstItemInHistoriesWithAFacility?.facilityId] || {};
      const { location = {} } = currentFacility;
      const {
        latitude: currentLat = defaultLat,
        longitude: currentLon = defaultLon,
      } = location;

      // Verify the facility has a valid Lat/Lon
      if (currentLat && currentLon) {
        setMapSettings({ center: { lat: currentLat, lng: currentLon }, zoom: 5 })
      }
    } else if (histories && firstItemInHistoriesWithALatLon) {
      setMapSettings({ center: { lat: firstItemInHistoriesWithALatLon.latitude, lng: firstItemInHistoriesWithALatLon.longitude }, zoom: 5 })
    } else {
      // Default view if no pins are available
      setMapSettings({ center: { lat: defaultLat, lng: defaultLon }, zoom: 2.3 })
    }
  }, [facilities, histories, setMapSettings]);


  return (
    <GoogleMapComponent
      eventTypesMap={eventTypesMap}
      mapSettings={mapSettings}
      markerKey="assetHistoryId"
      markersToRender={renderMarkers}
      polylinePopUpContent={polylinePopUpContent}
      polylines={trips}
      popUpContent={popUpContent}
    />
  );
}
