export const getDevices = async (props) => {
  const { apiUrl, organizationId, token } = props;
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {terms: {asset_mode: ["Device"]}}
        ]
      },
    },
    skip: 0,
    limit:1000,
  };
  const devices = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  return devices;
};