const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const searchHistories = async (props, state) => {
  const { token, organizationId } = props;
  const { assetId, page = 0 } = state;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must_not: [
          // No devices
          {
            terms: {
              // If the user is on the Inventory Snapshot page, then we do not need
              // to exclude this
              asset_mode: ["Device"],
            },
          },
          {
            term: {
              event: "External Scanned",
            },
          },
          {
            term: {
              event: "Proximity",
            },
          },
          {
            term: {
              event: "Moving Proximity",
            },
          },
          {
            term: {
              event_type: "device_telemetry",
            },
          },
          {
            term: {
              event: "Zone Arrival",
            },
          },
          {
            term: {
              event: "Zone Departure",
            },
          },
          // do not include records with a lastEvent of "Scan – LogisticsPro". Using Regex because of the dash character
          //Match for strings that start with Scan and end with LogisticsPro
          {
            regexp: {
              "event": {
                "value": "Scan.*LogisticsPro",
              }
            }
          },
        ],
        must: [
          // Give us assets from this organization
          {
            term: {
              organization_id: organizationId,
            },
          },

          // No batches either
          {
            bool: {
              must_not: [
                {
                  exists: {
                    field: "batch_id",
                  },
                },
              ],
            },
          }
        ],
        should: [

          // Should be an Inventory asset
          {
            term: {
              asset_mode: "Inventory",
            },
          },

          // Should be a Container asset
          {
            term: {
              asset_mode: "Container",
            },
          },
        ],
        "minimum_should_match": 1
      },
    },
    limit: 1000,
    page,
    sort: "time_of_log DESC",
  };

  // Asset Id - In the case we are looking for histories from a certain assetId
  if (assetId) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_id: assetId,
      },
    });
  }

  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error:
          "Failed to fetch asset history data, please contact system administrator.",
      };
    });

  return results;
};

export const searchRecentHistories = async (props, state) => {
  const { token, organizationId } = props;
  const { rangeStart, rangeEnd } = state;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must_not: [
          // No devices
          {
            terms: {
              // If the user is on the Inventory Snapshot page, then we do not need
              // to exclude this
              asset_mode: ["Device"],
            },
          },
          {
            term: {
              event: "External Scanned",
            },
          },
          {
            term: {
              event: "Proximity",
            },
          },
          {
            term: {
              event: "Moving Proximity",
            },
          },
          {
            term: {
              event_type: "device_telemetry",
            }
          },
          {
            term: {
              event: "Zone Arrival",
            }
          },
          {
            term: {
              event: "Zone Departure",
            }
          },
          // do not include records with a lastEvent of "Scan – LogisticsPro". Using Regex because of the dash character
          //Match for strings that start with Scan and end with LogisticsPro
          {
            regexp: {
              "event": {
                "value": "Scan.*LogisticsPro",
              }
            }
          },
        ],
        must: [
          // Give us assets from this organization
          {
            term: {
              organization_id: organizationId,
            },
          },

          // No batches either
          {
            bool: {
              must_not: [
                {
                  exists: {
                    field: "batch_id",
                  },
                },
              ],
            },
          },

          // Set range
          {
            range: {
              time_of_log: {
                gte: rangeStart,
                lte: rangeEnd,
              },
            },
          },
          // Only Inventory Assets
          {
            term: {
              asset_mode: "Inventory",
            },
          },
        ],
      },
    },

    limit: 5000,
    sort: "time_of_log DESC",
  };

  // Time Range - In the case we are looking for histories from a certain time range
  // if(rangeStart && rangeEnd) {
  //   elasticQuery.elasticSearchQuery.bool.must.push({
  //     range: {
  //       time_of_log: {
  //         gte: rangeStart,
  //         lte: rangeEnd,
  //       },
  //     },
  //   });
  // }

  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error:
          "Failed to fetch asset history data, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      inventoryHistoryTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const inventoryHistory = {
  searchHistories,
  searchRecentHistories,
  saveFilterSettings,
};
