import "moment-timezone";

// This is an older util file. Would like to fully invest in the /utils/api folder
// at some point

export function RetrieveNotifications(props, callback) {
  const { apiUrl, token, userId } = props;

  fetch(`${apiUrl}userNotifications/appUsers/${userId}`, {
    method: "GET",
    headers: {
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      callback(response.userNotifications);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function EditExistingUser(props, body, callback) {
  const { apiUrl, token, userId } = props;

  fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      callback(response.appUser);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveBatch(props, callback) {
  const { apiUrl, token, batchId } = props;

  fetch(`${apiUrl}batches/${batchId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      callback(response.batch);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveProduct(props, callback) {
  const { apiUrl, token, productId } = props;

  fetch(`${apiUrl}products/${productId}`, {
    method: "GET",
    headers: {
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      callback(response.product);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveBatchAssets(props, callback) {
  const { apiUrl, token, batchId } = props;

  fetch(`${apiUrl}batches/${batchId}/assets`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      const { assets = [] } = response;
      callback(assets);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveOrganization(props, callback) {
  const { apiUrl, token, organizationId } = props;

  fetch(`${apiUrl}organizations/organization/${organizationId}`, {
    method: "GET",
    headers: {
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      callback(response.organization);
    })
    .catch((err) => {
      console.log("error: " + err.message + " " + err.response);
    });
}

export function RetrieveQrBackgrounds(props, callback) {
  const { apiUrl, token } = props;

  fetch(`${apiUrl}qrBackgrounds`, {
    method: "GET",
    headers: {
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      callback(response.qrBackgrounds);
    });
}
