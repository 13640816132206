import { Grid, ListItemIcon, Typography } from '@mui/material';
import MaterialUiButton from '../../Buttons/MaterialUiButton/MaterialUiButton';
import ModalDialog from '../ModalDialog/ModalDialog'
import React from 'react'

function CreEditModal(props) {

  const {
    fields,
    handleCreateSubmit,
    handleEditSubmit,
    modalOpen,
    modalType,
    setModalOpen,
    title,
  } = props

  const content = (
    <>

      <Grid container xs={12} spacing={2}>
        {fields}
      </Grid>
      <Grid sx={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
      }}
      >
        {/* Cancel Button */}
        <Grid
          item xs={4}>
          <MaterialUiButton
            color="cancel"
            // cypressId={cypressIds.cancel + "-btn-cancel"}
            fullWidth
            label="Cancel"
            onClick={() => {
              setModalOpen(false);
            }}
            variant="outlined"
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="submit"
            // cypressId={cypressIds.create + "-btn-submit"}
            fullWidth
            label="Submit"
            onClick={() => {
              if (modalType === "Create") {
                handleCreateSubmit();
              } else {
                handleEditSubmit();
              }
            }}
            variant="contained"
          />
        </Grid>
      </Grid>
    </>
  )


  return (
    <div>
      <ModalDialog
        content={content}
        handleClose={() => {
          setModalOpen(false);
        }}
        hasFormFields={true}
        open={modalOpen}
        title={`${modalType} ${title}`}
      />
    </div>
  )
}

export default CreEditModal