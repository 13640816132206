import { Box, FormControl, IconButton, InputLabel, ListItemIcon, TextField, useTheme } from "@mui/material";
import { ReactComponent as AsteriskSolidIcon } from "../../../assets/faIcons/asterisk-solid.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiSVGIcon from "../../IconPicker/MuiSVGIcon";

export default function SimpleTextField(props) {
  const {
    autoComplete,
    autoFocus,
    classes,
    color,
    customIcon = <></>,
    defaultValue,
    disabled = false,
    error = false,
    errorText = "Required Field",
    externalLabel = true,
    FormHelperTextProps,
    fullWidth = true,
    helperText,
    id,
    InputLabelProps = {},
    InputProps = {},
    inputProps,
    label = "",
    labelPlacement = "top",
    labelStyles = {},
    margin,
    multiline = false,
    name,
    onChange,
    onClick = () => { },
    onDeleteIconClick = () => { },
    readOnly = false,
    required = false,
    rows,

    // The Custom icon sits next to the label
    // Should porbably replace the showDeleteIcon props with this at some point
    showCustomIcon = false,

    //Delete Icon Boolean
    showDeleteIcon = false,
    sx = {},
    size = "small",
    type = "",

    value,
    variant = "outlined",
  } = props;
  const theme = useTheme()
  const { palette = {} } = theme
  const { typography = {} } = palette
  const formattedLabel = label?.replace(/\s+/g, "-");

  let labelWithAstrisk;
  if (required) {
    labelWithAstrisk = (
      <Box sx={{ display: "flex" }}>
        {label}
        <MuiSVGIcon
          icon={<AsteriskSolidIcon/>}
          sx={{
            color: "red",
            fontSize: "10px",
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={
        labelPlacement === "start"
          ? {
            display: "flex",
            alignItems: "center",
          }
          : {}
      }
    >
      {externalLabel ? (
        <Box
          sx={{
            color: typography?.secondary,
            display: "flex",
            fontFamily: "Lato",
            fontWeight: "bold !important",
            justifyContent: "space-between",
            lineHeight: 1,
            marginBottom: `${labelPlacement === "start" ? "inherit" : ".5rem"}`,
            marginTop: `${labelPlacement === "start" ? "inherit" : ".2rem"}`,
            marginRight: `${labelPlacement === "start" ? ".5rem" : ""}`,
            ...labelStyles,
          }}
        >
          <InputLabel
            id={formattedLabel}
            sx={{
              color: typography?.secondary,
              fontFamily: "Lato",
              fontWeight: "bold",
            }}
          >
            {required ? labelWithAstrisk : label}
          </InputLabel>
          {showDeleteIcon ? (
            <IconButton
              aria-label={`Delete ${label}`}
              onClick={() => onDeleteIconClick()}
            >
              <DeleteIcon
                fontSize="small"
              />
            </IconButton>
          ) : null}
          {showCustomIcon ? customIcon : null}
        </Box>
      ) : null}
      <FormControl
        className="simple-select"
        error={error}
        size={size}
        sx={{ width: "100%" }}
        variant={variant ? variant : "standard"}
      >
        <TextField
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          classes={classes}
          color={color}
          defaultValue={defaultValue}
          disabled={disabled}
          error={error}
          FormHelperTextProps={FormHelperTextProps}
          fullWidth={fullWidth}
          helperText={error ? errorText : helperText}
          label={externalLabel ? null : label}
          id={id}
          InputLabelProps={{
            required: false,
            //if externalLabel is true, set shrink to false
            shrink: externalLabel ? false : undefined,
            ...InputLabelProps,
            style: {
              ...InputLabelProps?.style,
              //if externalLabel is true, make it visible and hide this one. Do that by setting fontSize to 0 and color to transparent
              fontSize: externalLabel ? 0 : "inherit",
              color: externalLabel ? "transparent" : "inherit",
            },
          }}
          inputProps={inputProps}
          InputProps={{
            ...InputProps,
            readOnly,
          }}
          margin={margin}
          multiline={multiline}
          name={name}
          onChange={(event) => {
            onChange(event);
          }}
          onClick={() => onClick()}
          required={required}
          rows={rows}
          type={type}
          size={size}
          sx={sx}
          value={value}
          variant={variant}
        />
      </FormControl>
    </Box>
  );
}
