import { formatClassifications } from "../utils/helpers";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export async function init(props) {
  const batches = await retrieveBatches(props);
  const childOrganizations = await retrieveChildOrganizations(props);
  const classifications = await retrieveClassifications(props);
  const customs = await retrieveCustoms(props);
  const devices = await retrieveDevices(props);
  const displayConfig = await retrieveDisplayConfig(props);;
  const facilities = await retrieveFacilities(props);
  const organization = await retrieveOrganization(props);
  const products = await retrieveProducts(props);
  const usersMap = await retrieveAppUsers(props);
  const zones = await retrieveZones(props);

  return {
    batches,
    childOrganizations,
    classifications,
    customs,
    displayConfig,
    devices,
    facilities,
    organization,
    products,
    usersMap,
    zones,
  };
}

export const retrieveAppUsers = async (props) => {
  const results = await fetch(`${apiUrl}appUsers`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }

      return res.appUsers.reduce((x, y) => {
        return {
          ...x,
          [y.appUserId]: y,
        };
      }, {});
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const retrieveOrganization = async (props) => {
  const results = await fetch(
    `${apiUrl}organizations/organization/${props.organizationId}/`,
    {
      headers: {
        "Content-Type": "application/json",
        "auth-token": props.token,
      },
    }
  )
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res.organization;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });

  return results;
};

export const retrieveFacilities = async (props) => {
  const results = await fetch(`${apiUrl}facilities/`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }

      return res.facilities.reduce((x, y) => {
        return {
          ...x,
          [y.facilityId]: y,
        };
      }, {});
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const retrieveZones = async (props) => {
  const results = await fetch(`${apiUrl}zones/`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res.zones.reduce((x, y) => {
        return {
          ...x,
          [y.zoneId]: y,
        };
      }, {});
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const retrieveProducts = async (props) => {
  const results = await fetch(`${apiUrl}products`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }

      return res.products.reduce((x, y) => {
        return {
          ...x,
          [y.productId]: y,
        };
      }, {});
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const retrieveBatches = async (props) => {
  const results = await fetch(`${apiUrl}batches`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }

      return res.batches.reduce((x, y) => {
        return {
          ...x,
          [y.batchId]: y,
        };
      }, {});
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const retrieveClassifications = async (props) => {
  const results = await fetch(`${apiUrl}classifications`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return formatClassifications(res);
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const retrieveCustoms = async (props) => {
  const results = await fetch(`${apiUrl}customs`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res.customs;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const retrieveDisplayConfig = async (props) => {
  const results = await fetch(`${apiUrl}clientOrganizationConfig`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        // previously we were returning "return { error: res.error }". 
        // Now we are retruning an empty array. As the error message was stating 
        // "Hey success is false cause they do not have any display configs. So end of day that means its an empty array"
        return [];
      }
      return res.clientOrganizationConfig.gridConfigs;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const retrieveChildOrganizations = async (props) => {
  const results = await fetch(`${apiUrl}organizations/children`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }

      return res.organizations.reduce((x, y) => {
        return {
          ...x,
          [y.organizationId]: y,
        };
      }, {});
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

export const retrieveDevices = async (props) => {
  const { organizationId, token } = props;
  const elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            bool: {
              must: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    limit: 1000,
  };
  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((res) => res.json())
    .then((res) => {
      return res.assets.reduce((x, y) => {
        return {
          ...x,
          [y.assetId]: y,
        };
      }, {});
    })
    .catch((err) => {
      console.log(err);
      return {
        error: err,
      };
    });

  return results;
};

export const organization = {
  init,
  retrieveAppUsers,
  retrieveBatches,
  retrieveChildOrganizations,
  retrieveClassifications,
  retrieveDisplayConfig,
  retrieveDevices,
  retrieveFacilities,
  retrieveOrganization,
  retrieveProducts,
  retrieveZones,
};
