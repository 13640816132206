// Desc: This file contains the filter component for the Inventory Map page. It contains the filter fields for the Inventory Map page.
import { Box, Card, CardContent, Grid } from "@mui/material";
import { isEqual } from "lodash";
import { isValidDateObject } from "../../../globalStore/utils/helpers";
import { searchAssets } from "../../../utils/API/Assets/Assets";
import { useSelector } from "react-redux";
import AutocompleteSelect from "../../../components/Forms/FieldTypes/AutocompleteSelect";
import ClassificationFilters from "../../../components/Selects/ClassificationsSelector/ClassificationFilters";
import CustomFilters from "../../../components/Selects/CustomsSelector/CustomFilters";
import Loading from "../../../components/Loading/Loading";
import LXDatePicker from "../../../components/Forms/FieldTypes/DatePicker";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import React, { useState } from "react";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";
import RadioList from "../../../components/Forms/FieldTypes/RadioList";

export default function InventoryMapFilter(props) {
  const {
    apiUrl,
    assetMode = "Inventory",
    // The exportWithCard prop is used to determine if the filters get exported with the MUI Card component
    exportWithCard = true,
    isInventory = true,

    // The go back prop adds another button to the filter component. This should be a { label, onClick } object.
    secondButton = null,
    organizationId,
    onSubmitEnd = () => {},
    setState,
    showAssetTypeRadioGroup = false,
    state,
    token,
    userRoles = {},
  } = props;
  const { filters = {} } = state;
  const { endDate, startDate, tag, facility = { name: "" } } = filters;
  const {
    "View Inventory": viewInventory = null,
    "View Asset History": viewAssetHistory = null,
  } = userRoles;

  const { customs, facilities } = useSelector(
    (state) => state.organization,
    isEqual
  );

  const facilityArray = facilities
    ? Object.values(facilities).sort((a, b) => {
        return (a.name || "").localeCompare(b.name || "");
      })
    : [];

  //get classifications from redux store
  const { classifications = {} } = useSelector(
    (state) => state.organization,
    isEqual
  );

  const classificationsList = Object.values(classifications || {}).filter(
    (classification) =>
      classification.assetMode === "Inventory" || !classification.assetMode
  );

  const customsList = customs.filter(
    (custom) => custom.assetMode === "Inventory" || !custom.assetMode
  );

  const [canSubmit, setCanSubmit] = useState(true);
  //filterLoading variable to show loading spinner when filters are being applied
  const [filterLoading, setFilterLoading] = useState(false);

  function handleFilterApply() {
    setFilterLoading(true);
    //use searchAssets to filter the data in the map
    searchAssets(
      {
        apiUrl,
        token,
        organizationId,
        assetMode,
        facilityId: state.filters.facility?.facilityId,
        isInventory,
      },
      { filters: state.filters }
    ).then((results) => {
      setFilterLoading(false);
      setState((prevState) => {
        return {
          ...prevState,
          assets: results.assets,
          count: results.count,
          page: Math.floor(state.filters.start / state.filters.limit),
          mapTableData: [],
        };
      });
      onSubmitEnd();
    });
  }

  const fieldStyle = { margin: "1rem 0" };

  const filterContent = (
    <Grid container>
      {/* //spinner to show loading when filters are being applied */}
      {filterLoading && (
        <Loading
          styles={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}

      {/* From Date / To Date / Category / Display Button */}
      <Grid item xs={3} sx={{ padding: "0 1rem" }}>
        {/* From Date */}
        <Box sx={fieldStyle}>
          <LXDatePicker
            format="MM/dd/yyyy"
            label="From Date (required)"
            onChange={(date) => {
              //if endDate is before date, set canSubmit to false. Dont set canSubmit to false if date is the same day as enddate
              if (
                date > endDate &&
                date.toDateString() !== endDate.toDateString()
              ) {
                setCanSubmit(false);
                setState((prevState) => ({
                  ...prevState,
                  filters: { ...prevState.filters, startDate: date },
                }));
              } else {
                //user can only submit if both dates are valid
                setCanSubmit(
                  isValidDateObject(date) && isValidDateObject(endDate)
                );
                setState((prevState) => ({
                  ...prevState,
                  filters: { ...prevState.filters, startDate: date },
                }));
              }
            }}
            slotProps={{
              textField: {
                helperText: !canSubmit && "Invalid date",
                InputLabelProps: {
                  shrink: false,
                  style: { color: "transparent" },
                },
                label: "From Date",
                size: "large",
              },
            }}
            value={startDate}
          />
        </Box>
        {/* To Date */}
        <Box sx={fieldStyle}>
          <LXDatePicker
            format="MM/dd/yyyy"
            label="To Date (required)"
            onChange={(date) => {
              //if enddate is before date, set canSubmit to false. Dont set canSubmit to false if date is the same day as enddate
              if (
                date < startDate &&
                date.toDateString() !== startDate.toDateString()
              ) {
                setCanSubmit(false);
                setState((prevState) => ({
                  ...prevState,
                  filters: { ...prevState.filters, endDate: date },
                }));
              } else {
                //user can only submit if both dates are valid
                setCanSubmit(
                  isValidDateObject(date) && isValidDateObject(startDate)
                );
                setState((prevState) => ({
                  ...prevState,
                  filters: { ...prevState.filters, endDate: date },
                }));
              }
            }}
            slotProps={{
              textField: {
                helperText: !canSubmit && "Invalid date",
                InputLabelProps: {
                  shrink: false,
                  style: { color: "transparent" },
                },
                label: "To Date",
                size: "large",
              },
            }}
            value={endDate}
          />
        </Box>

        {/* Display Button */}
        <Box sx={{ marginTop: "1rem" }}>
          <MaterialUiButton
            data-testid="Display Button"
            fullWidth
            label="Apply Filters"
            onClick={() => handleFilterApply()}
          />
          {secondButton ? (
            <MaterialUiButton
              data-testid="Display Button"
              fullWidth
              label={secondButton?.label}
              onClick={() => secondButton?.onClick()}
              sx={{ marginTop: ".5rem" }}
            />
          ) : null}
        </Box>
      </Grid>

      {/* Row/Bin / Event */}
      <Grid item xs={3} sx={{ padding: "0 1rem" }}>
        {/* Tag ID */}
        <Box sx={fieldStyle}>
          <SimpleTextField
            onChange={(event) =>
              setState((prevState) => ({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  tag: event.target.value,
                },
              }))
            }
            fullWidth
            size="large"
            value={tag}
            label="Tag Id"
          />
        </Box>

        {/* Facility */}
        <Box sx={fieldStyle}>
          <AutocompleteSelect
            id="facilityName"
            getOptionLabel={(option) => option.name}
            label="Facility Name"
            onChange={(event, newValue) => {
              setState((prevState) => ({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  facility: newValue,
                },
              }));
            }}
            options={facilityArray}
            size="large"
            value={facility}
            variant="outlined"
          />
        </Box>

        {/* Asset Type Radio Group */}
        {showAssetTypeRadioGroup ? (
          <Box sx={fieldStyle}>
            <RadioList
              label="Asset Type"
              name="assetType"
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  filters: {
                    ...prevState.filters,
                    assetType: event.target.value,
                  },
                }));
              }}
              options={[
                { label: "Inventory", value: "Inventory", disabled: !viewInventory},
                { label: "Asset", value: "Asset", disabled: !viewAssetHistory},
              ]}
              row
              value={state?.filters?.assetType}
            />
          </Box>
        ) : null}
      </Grid>

      {/* Classification Filters */}
      <ClassificationFilters
        classifications={classificationsList}
        state={state}
        setState={setState}
      />

      {/* Custom Filters */}
      <CustomFilters customs={customsList} state={state} setState={setState} />
    </Grid>
  );

  return exportWithCard ? (
    <Card>
      <CardContent>{filterContent}</CardContent>
    </Card>
  ) : (
    filterContent
  );
}
