import { useMemo } from "react";
import { CssBaseline, Typography, useTheme } from "@mui/material";
import { hideAlert } from "../../globalStore/slices/alert/alertSlice";
import { useFreshDeskAuth } from "../../app/FreshDesk/useFreshDeskAuth";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import FreshDeskWidget from "../../app/FreshDesk/FreshDeskWidget";
import MaterialConfirmationModal from "../Modals/MaterialConfirmationModal";
import SideNav from "./SideNav/SideNav";
import TopNav from "./TopNav/TopNav";

export default function AuthLayout(props) {
  const theme = useTheme();

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  const enableFeatures = query.get("enableFeatures") || false;
  const classes = {
    root: {
      backgroundColor: "#E6E7E8",
      display: "flex",
      fontSize: "1rem",
      height: "100vh",
      lineHeight: "1.5",
      overflow: "auto",
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      minHeight: "100vh",
      flexGrow: 1,
      padding: theme.spacing(3, 5, 3, 3),
      marginTop: enableFeatures ? "3rem" : "0",
    },
  };
  const sideNavWidth = 90;
  const appBarHeight = 60;
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const token = useSelector((state) => state.user.token);

  useFreshDeskAuth(token);

  return (
    <>
      <CssBaseline />
      <Typography component="div" sx={classes.root}>
        <FreshDeskWidget />
        <SideNav sideNavWidth={sideNavWidth} appBarHeight={appBarHeight} />

        {/* Until we develop this further, we are hiding this in features mode */}
        {enableFeatures ? <TopNav {...props} /> : null}
        <Typography component="main" sx={classes.content}>
          <MaterialConfirmationModal
            closeModal={() => {
              dispatch(hideAlert({}));
            }}
            content={alert.text}
            modalOpen={alert.show}
            severity={alert.type || "error"}
          />

          {props.children ? props.children : null}
          {/* Bottom padding for help desk widget */}
          <div style={{ paddingBottom: "75px" }}></div>
        </Typography>
      </Typography>
    </>
  );
}
