import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useHistory } from "react-router-dom";
import { useTranslation as UseTranslation } from "react-i18next";
import MaterialUiDataGridPremium from "../../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";

export default function MapTable(props) {
  const history = useHistory();

  const { facilities, mapTableData, zones } = props;

  const { t } = UseTranslation();

  const timeZone = props.timeZone?.value || null;

  const handleClick = (row) => {
    const url = row.batchId
      ? `/batches/${row.batchId}/history/${row.assetType ? row.assetType : ""}`
      : `/assetSnapshot/${row.assetId}`;
    history.push(url);
  };

  const mapTableColumns = [
    {
      field: "timeOfLog",
      groupable: false,
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { row } = data;
        const { timeOfLogLong } = row;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleClick(row);
            }}
          >
            {unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.timeOfLogLong || row.timeOfLog || t("dnpinput1");
      },
    },
    {
      field: "tag",
      headerName: "Asset Tag",
      renderCell: (data) => {
        if(data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { tag } = row;
        return (
          <div
            className={`pointer`}
            onClick={() => {
              handleClick(row);
            }}
          >
            {tag ? tag : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.tag ? row.tag : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        return row.tag ? row.tag : null;
      },
    },
    {
      field: "lastEvent",
      headerName: "Last Event",
      renderCell: (data) => {
        if(data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { lastEvent } = row;
        return (
          <div
            className={`pointer`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              handleClick(row);
            }}
          >
            {lastEvent ? lastEvent : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.lastEvent ? row.lastEvent : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        return row.lastEvent ? row.lastEvent : null;
      },
    },
    {
      field: "facility",
      headerName: "Facility Name",
      renderCell: (data) => {
        if(data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { facility, facilityId, latitude, longitude } = row;
        return (
          <div
            className={`pointer`}
            onClick={() => {
              handleClick(row);
            }}
          >
            {facility
              ? facility
              : !latitude || !longitude
              ? t("dnpinput1")
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered"}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { facilityId } = row;
        return facilityId &&
          facilities[facilityId] &&
          facilities[facilityId].name
          ? facilities[facilityId].name
          : "Not Registered";
      },
      groupingValueGetter: (value, row) => {
        const { facilityId } = row;
        return facilityId &&
          facilities[facilityId] &&
          facilities[facilityId].name
          ? facilities[facilityId].name
          : "Not Registered";
      },
    },
    {
      field: "zone",
      headerName: "Zone",
      renderCell: (data) => {
        if(data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { zone = {} } = row;
        const { zoneId = "" } = zone;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleClick(row);
            }}
          >
            {zoneId && zones[zoneId] ? zones[zoneId].name : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneId = "" } = zone;
        return zoneId && zones[zoneId] ? zones[zoneId].name : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneId = "" } = zone;
        return zoneId && zones[zoneId] ? zones[zoneId].name : null;
      },
    },
    {
      field: "binLocation",
      headerName: "Bin Location",
      renderCell: (data) => {
        if(data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { binLocation = "" } = row;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleClick(row);
            }}
          >
            {binLocation ? binLocation : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.binLocation ? row.binLocation : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        return row.binLocation ? row.binLocation : null;
      },
    },
  ];

  return (
    <MaterialUiDataGridPremium
      autoHeight={false}
      height={"56vh"}
      checkboxSelection={false}
      columns={mapTableColumns}
      exportFileName={"Asset-Map-Table"}
      getRowId={(row) => row.assetId}
      rows={mapTableData ? mapTableData : []}
    />
  );
}
