import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getFixedNodeById } from "../api";


export default function ViewNodeInformation(props) {

  const classes = {
    root: {
      width: "100%",
    },
    heading: {
      marginLeft: ".5rem",
    },
    accordionSummary: {
      flexDirection: "row-reverse",
      padding: 0,
    },
  }

  const { apiUrl, token } = props;
  const { selectedNode = {} } = props.state;

  const [mountPoints, setMountPoints] = useState([]);

  useEffect(() => {
    getFixedNodeById({ apiUrl, token }, selectedNode.fixedNodeId).then(
      (res) => {
        setMountPoints(res.fixedNode.mountPoints || []);
      }
    );
  }, [selectedNode.fixedNodeId, apiUrl, token]);

  return (
    <div sx={classes.root}>
      {mountPoints?.length > 0 ? (
        mountPoints
          // Sorts the mountpoints alphabetically by their names
          .sort((a = {}, b = {}) => {
            const nameA = a.name ? a.name.toUpperCase() : a.name;
            const nameB = b.name ? b.name.toUpperCase() : b.name;
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            }
            return null;
          })
          .map((mountPoint, idx) => (
            <Accordion key={`${mountPoint.name}-${idx}`}>
              <AccordionSummary
                sx={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={classes.heading}>
                  {mountPoint.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Typography>
                      <strong>X (meters):</strong> {mountPoint.xyz.x}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <strong>Y (meters):</strong> {mountPoint.xyz.y}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <strong>Z (meters):</strong> {mountPoint.xyz.z}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {mountPoint.slots?.length > 0
                      ? mountPoint.slots.map((slot) => (
                          <Typography>
                            <strong>{slot.angle}° Angle: </strong>
                            {slot.name}
                          </Typography>
                        ))
                      : null}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
      ) : (
        <div>
          No mount points configured for {selectedNode.node}. Please configure
          mount points in the edit node window (
          <EditIcon/>)
        </div>
      )}
    </div>
  );
}
