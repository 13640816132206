import escapeCharacters from "../../../utils/escapedCharacters";
import moment from "moment-timezone";

export const searchHistories = async (props, state) => {
  const {
    apiUrl,
    batchId,
    classifications,
    isInventory = false,
    organizationId,
    token,
  } = props;
  const { assetId, filters = {}, isExport, page = 0 } = state;
  let {
    allDevicesMap = {},
    assetCategories,
    assetIds,
    assetType,
    binLocation,

    // Use binLocationWildCard when you're doing a wildcard search on a binLocation
    binLocationWildCard,
    binLocations,
    //destructure classifications from filters under a different name to avoid confusion.
    classifications: classificationsFromFilters,
    customs,
    endDate,
    events,
    hideScans = true,
    limit,
    locals,
    locations,
    pca,
    proximity = false,
    startDate,
    tag,
    type,
    users,
    zones,
  } = filters;
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              organization_id: organizationId,
            },
          },
          {
            bool: {
              must_not: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
        must_not: [
          // No devices
          {
            terms: {
              // If the user is on the Inventory Snapshot page, then we do not need
              // to exclude this
              asset_mode: ["Device"],
            },
          },
          {
            term: {
              event: "External Scanned",
            },
          },
          {
            term: {
              event: "Proximity",
            },
          },
          {
            term: {
              event: "Moving Proximity",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    limit: isExport ? 25000 : limit || 25,
    page,
    // sort: `${sortedString}`,
    sort: "timeOfLog DESC",
  };

  // Logic for inventory searches
  if (isInventory) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_mode: "Inventory",
      },
    });
  }

  // Logic for asset searches
  if(!isInventory){
    elasticQuery.elasticSearchQuery.bool.must_not.push({
      term: {
        asset_mode: "Inventory",
      },
    });
  }

  // escape special characters from string inputs
  let escapedAssetType = assetType ? escapeCharacters(assetType) : null;

  // Asset Id - In the case we are looking for histories from a certain assetId
  if (assetId) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_id: assetId,
      },
    });
  }

  // Asset Tag
  if (assetIds && assetIds.length) {
    let assetIdArray = assetIds.map((assetId) => {
      return assetId.value;
    });
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        asset_id: assetIdArray,
      },
    });
  }

  // Asset Type
  if (escapedAssetType || type) {
    if (escapedAssetType) {
      elasticQuery.elasticSearchQuery.bool.must.push({
        term: {
          asset_type: escapedAssetType,
        },
      });
    } else {
      const typeList = type.map((item) => item.value);
      elasticQuery.elasticSearchQuery.bool.must.push({
        terms: {
          asset_type: typeList,
        },
      });
    }
  }

  // Batch ID
  if (batchId) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        batch_id: batchId,
      },
    });
  }

  // Bin Location
  if (binLocation?.length || binLocations?.length) {
    let binLocationsArray = [];

    if (binLocation?.length) {
      binLocationsArray = binLocation?.map((bin) => {
        return {
          term: {
            "zone.bin_location.keyword": bin.value,
          },
        };
      });
    } else {
      binLocationsArray = binLocations?.map((bin) => {
        return {
          term: {
            "zone.bin_location.keyword": bin.value,
          },
        };
      });
    }

    elasticQuery.elasticSearchQuery.bool.must.push({
      bool: {
        should: [
          {
            nested: {
              path: "zone",
              query: {
                bool: {
                  should: binLocationsArray,
                },
              },
            },
          },
        ],
      },
    });
  }

  // Bin Location Wild Card
  if (binLocationWildCard) {
    let escapedInput = escapeCharacters(binLocationWildCard);
    elasticQuery.elasticSearchQuery.bool.must.push({
      regexp: {
        "bin_location.wildcard": {
          value: `${escapedInput}.*`,
          rewrite: "constant_score",
        },
      },
    });
  }

  // Categories
  if (assetCategories?.length > 0) {
    // Push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { category: assetCategories },
    });
  }

  // Classifications
  if (classifications) {
    const classificationFilters = Object.keys(filters || {})
      .filter(
        (filterKey) => classifications[filterKey] && filters[filterKey] !== null
      )
      .map((filterKey) => filters[filterKey]);

    if (classificationFilters?.length) {
      classificationFilters?.map((classArray) => {
        // We initially declare the object we are going to push into the query
        let queryObject = {
          nested: {
            path: "classification_data",
            query: {
              bool: {
                must: [],
                should: [],
                minimum_should_match: 1,
              },
            },
          },
        };

        // We build up the queryObject here
        classArray?.map((childClass) => {
          // Make sure the parentId is present
          if (queryObject.nested.query.bool.must.length === 0) {
            queryObject.nested.query.bool.must.push({
              match: {
                "classification_data.key.keyword": childClass.parentLabel,
              },
            });
          }

          // Push the children
          queryObject.nested.query.bool.should.push({
            match: {
              "classification_data.value.keyword": childClass.label,
            },
          });
          return null;
        });

        elasticQuery.elasticSearchQuery.bool.must.push(queryObject);

        return null;
      });
    }
  }

  // Device Filter - build filter query string from all the asset ids found in the allDevicesMap to filter out asset histories that may have come from devices
  const filterDevicesString = Object.keys(allDevicesMap).map((deviceId) => {
    return deviceId;
  });

  elasticQuery.elasticSearchQuery.bool.must_not.push({
    terms: {
      asset_id: filterDevicesString,
    },
  });

  let shouldMatchCount = 0;
  // Customs
  if (customs && customs.length > 0) {
    customs.forEach((item) => {
      // Makes sure all fields without a value are not included in the query
      if (item.value !== "") {
        // This sets up for should matches
        shouldMatchCount += 1;

        elasticQuery.elasticSearchQuery.bool.should.push({
          nested: {
            path: "custom_data",
            query: {
              bool: {
                must: [
                  {
                    match: {
                      "custom_data.key.keyword": item.label,
                    },
                  },
                  {
                    match: {
                      "custom_data.value.keyword": item.value,
                    },
                  },
                ],
              },
            },
          },
        });
      }
    });
  }

  //Classifications From Filters
  if (classificationsFromFilters && classificationsFromFilters.length > 0) {
    classificationsFromFilters.forEach((item) => {
      // This sets up for should matches
      shouldMatchCount += 1;

      elasticQuery.elasticSearchQuery.bool.should.push({
        nested: {
          path: "classification_data",
          query: {
            bool: {
              must: [
                {
                  match: {
                    "classification_data.key.keyword": item.label,
                  },
                },
                {
                  match: {
                    "classification_data.value.keyword": item.value,
                  },
                },
              ],
            },
          },
        },
      });
    });
  }

  if (shouldMatchCount > 0) {
    elasticQuery.elasticSearchQuery.bool.minimum_should_match =
      shouldMatchCount;
  }
  //else set shouldMatchCount to null
  else {
    elasticQuery.elasticSearchQuery.bool.minimum_should_match = null;
  }

  // Events
  if (events && events.length) {
    // Push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { event: events },
    });
  }

  // Facility
  if (locations && locations.length) {
    // We build the array
    let locationsSet = locations.map((e) => {
      return e.value?.facilityId || e;
    });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { facility_id: locationsSet },
    });
  }

  // PCA
  if (pca) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { flagged: true },
    });
  }

  // Proximity
  if (proximity) {
    elasticQuery.elasticSearchQuery.bool.must_not.push({
      term: {
        event_type: "device_proximity",
      },
    });
  }

  // Scans
  if (hideScans) {
    elasticQuery.elasticSearchQuery.bool.must_not.push({
      regexp: {
        event: {
          value: "Scan.*LogisticsPro",
        },
      },
    });
  }

  // State
  if (locals && locals.length) {
    // We build the array
    let localsArray = locals.map((e) => {
      return e.value;
    });
    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { state: localsArray },
    });
  }

  // Tag
  if (tag) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      regexp: {
        asset_tag: {
          value: `${tag}.*`,
          case_insensitive: true,
          rewrite: "constant_score",
        },
      },
    });
  }

  // Users
  if (users?.length) {
    // Push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { app_user_id: users },
    });
  }

  // Zones
  if (zones && zones.length) {
    let zonesArray = zones.map((zone) => {
      const { internalZoneType = null, value = "" } = zone;

      // if {zoneId}.internalZoneId === 'processing' then we know the zone is a
      // pZone. If that value is === 'target' then we know the zone is a tZone.

      if (internalZoneType === "processing") {
        return { "zone.p_zone_id": value };
      } else if (internalZoneType === "target") {
        return { "zone.t_zone_id": value };
      } else {
        return { "zone.zone_id": value };
      }
    });
    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      nested: {
        path: "zone",
        query: {
          bool: {
            must: [
              {
                terms: {
                  "zone.zone_id": zonesArray,
                },
              },
            ],
          },
        },
      },
    });
  }

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  if (startDate && endDate) {
    if (moment(startDate).isValid() || moment(endDate).isValid()) {
      elasticQuery.elasticSearchQuery.bool.must.push({
        range: {
          time_of_log: {
            gte: moment(startDate).isValid()
              ? moment(startDate).startOf("day").valueOf()
              : null,
            lte: moment(endDate).isValid()
              ? moment(endDate).endOf("day").valueOf()
              : "now",
          },
        },
      });
    }
  }

  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error:
          "Failed to fetch asset history data, please contact system administrator.",
      };
    });

  return results;
};

// Devices indicate a trip has started with "Depart". Indicate they are on the trip with "EnRoute". 
// And indicate the trip has ended with "Arrive".
export const retrieveAssetTrips = async (props) => {
  const {
    apiUrl = '',
    assetId = '',
    organizationId = '',
    token = '',
  } = props;
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          // Asset ID
          {
            term: {
              asset_id: assetId,
            },
          },
          // Organization ID
          {
            term: {
              organization_id: organizationId,
            },
          },
        ],
        should: [
          {
            term: {
              event: 'Arrive',
            },
          },
          {
            term: {
              event: 'Depart',
            },
          },
          {
            term: {
              event: 'EnRoute',
            },
          }
        ],
        minimum_should_match: 1,
      },
    },
    limit: 25000,
    sort: "timeOfLog DESC",
  };

  // Asset Id - In the case we are looking for histories from a certain assetId
  if (assetId) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_id: assetId,
      },
    });
  }

  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error:
          "Failed to fetch asset history data, please contact system administrator.",
      };
    });

  return results;
};
