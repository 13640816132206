import { useHistory } from "react-router-dom";

import { exportSpreadsheet } from "../xlsx/xlsx";
import buildColumns from "./columns";
import MaterialUiDataGridPremium from "../../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";

export default function Table(props) {
  const {
    apiUrl,
    appUserType,
    classifications,
    customs,
    facilities,
    handleSave,
    organizationId,
    products,
    setLoading,
    setState,
    state,
    token,
    toolBarButtons,
    userId,
    useSelector,
    zones,
  } = props;

  const displayConfigs =
    useSelector((state) => state.organization.displayConfig) || [];
  const history = useHistory();
  const specificConfig = displayConfigs.find(
    (element) => element.type === "InventoryHistory"
  );
  const columnOrder = specificConfig?.columns || null;
  const timeZone = props.timeZone?.value || null;
  const { historyTableColumns } = buildColumns({
    apiUrl,
    appUserType,
    classifications,
    columnOrder,
    customs,
    displayColumnOptions: state.filters?.displayColumnOptions || {},
    facilities,
    handleSave,
    history,
    parentAssetIdTagMap: state.parentAssetIdTagMap,
    setState,
    timeZone,
    token,
    userId,
    zones,
  });

  function handleExport() {
    const selectedAssetsCount = Object.keys(state.selectedAssets).length;
    if (selectedAssetsCount === 0) {
      if (state.histories?.length >= 25000) {
        setLoading(true);
        if (
          !window.confirm(
            `Can not export information for more than 25,000 events at once. This export will contain information for the first 25,000 out of ${state.histories?.length} events. Do you still wish to continue?`
          )
        ) {
          setLoading(false);
          return;
        }
      }
      exportSpreadsheet(state.histories || [], facilities, timeZone, {
        products,
        classifications,
        zones,
        apiUrl,
        token,
        organizationId,
      });
    } else {
      if (selectedAssetsCount >= 25000) {
        setLoading(true);
        if (
          !window.confirm(
            `Can not export information for more than 25,000 assets at once. This export will contain information for the first 25,000 out of ${selectedAssetsCount} assets. Do you still wish to continue?`
          )
        ) {
          setLoading(false);
          return;
        }
      }
      const selectedAssetsArr = [];
      for (const [key, value] of Object.entries(state.selectedAssets)) {
        selectedAssetsArr.push(value);
      }
      exportSpreadsheet(selectedAssetsArr || [], facilities, timeZone, {
        products,
        classifications,
        zones,
        apiUrl,
        token,
        organizationId,
      });
    }

    // code which considers non mui filters on export
    // dispatchGlobal(
    //   searchHistories({ filters: state.filters, isExport: true })
    // ).then((results) => {
    //   if (!results.payload.success) {
    //     setConfirm({
    //       modalShow: true,
    //       text: results.payload.error,
    //       error: true,
    //     });
    //   } else {
    //     exportSpreadsheet(
    //       results.payload.histories || [],
    //       facilities,
    //       timeZone,
    //       {
    //         products,
    //         classifications,
    //         zones,
    //         apiUrl,
    //         token,
    //         organizationId,
    //       }
    //     ).then((finished) => {
    //       setLoading(false);
    //     });
    //   }
    // });
  }

  function handleRowSelect(selectedRowIds) {
    let updatedSelectedAssets = {};

    const assetsList = state.histories || [];
    for (let asset of assetsList) {
      for (let rowId of selectedRowIds) {
        if (asset.assetHistoryId === rowId) {
          updatedSelectedAssets = {
            ...updatedSelectedAssets,
            [asset.assetHistoryId]: asset,
          };
        }
      }
    }

    setState((s) => {
      return {
        ...s,
        selectedAssets: updatedSelectedAssets,
      };
    });
  }

  return (
    <MaterialUiDataGridPremium
      autoHeight={false}
      checkboxSelectedIds={Object.keys(state.selectedAssets)}
      checkboxSelection={true}
      columns={historyTableColumns}
      delayedLoad={true}
      disableAggregation={false}
      exportFileName={"Inventory-History"}
      getRowId={(row) => row.assetHistoryId}
      handleRowSelect={handleRowSelect}
      loading={false}
      needsCustomToolbar={true}
      rowCount={state.histories?.length}
      rows={state.histories || []}
      showGridToolBar={true}
      toolBarButtons={toolBarButtons}
    />
  );
}
