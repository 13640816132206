import {
  removeFacility,
  updateFacilities,
  updateFacility as singleFacilityUpdate,
} from "../../../globalStore/slices/organization/organizationSlice";

export const checkFacility = async (props) => {
  const { apiUrl, facilityId, token } = props;
  const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((results) => results.json())
    .then((results) => results);

  return results;
};

export const deleteFacilityImage = async (props) => {
  const { apiUrl, facilityId, facilityImageId, dispatchGlobal, token } = props;

  const results = await fetch(
    `${apiUrl}facilities/${facilityId}/images/${facilityImageId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    }
  )
    .then((response) => response.json())
    .then((response) => {
      const updatedFacility = response.facility;
      //set propertiesMap in updatedFacility to empty object
      updatedFacility.propertiesMap = {};
      // TODO:
      dispatchGlobal(singleFacilityUpdate(updatedFacility));
      return response;
    })
    .catch((err) => console.log(err));
  return results;
};

export const getZoneHashMap = async (props, zoneIds) => {
  const { apiUrl, token } = props;
  const zoneHashMap = {};

  const results = await fetch(`${apiUrl}zones/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((response = {}) => {
      if (response.success) {
        // Create a hashmap of the organizations zones
        response.zones.forEach((zone) => {
          zoneHashMap[zone.zoneId] = zone;
        });
      }
      return zoneHashMap;
    })
    .catch((err) => console.log(err));
  return results;
};

export const updateFacility = async (props, content) => {
  const { apiUrl, facilityId, token, dispatchGlobal } = props;
  const body = {
    propertiesMap: {
      //if content is an empty object, set propertiesMap to null, otherwise, spread content
      facilityImage: Object.keys(content).length === 0 ? null : { ...content },
    },
  };

  const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((results) => results.json())
    .then((results) => {
      dispatchGlobal(
        updateFacilities({
          body: results.facility,
          facilityId: results.facility?.facilityId,
        })
      );
      return results;
    });

  return results;
};

export const uploadPDFImage = async (props) => {
  const {
    apiUrl,
    facilityId,
    file,
    handleSnackbar,
    setState,
    token,
    dispatchGlobal,
  } = props;
  const { name } = file[0];
  const body = new FormData();
  const reader = new FileReader();
  let image = null;

  reader.onload = async () => {
    image = reader.result;

    //set the body based on file type. If the file is a PDF, set the convertToImageTypes to PNG
    //if the file is an image, append the image to the body as pngimagedata and dont set conversion type
    if (file[0].type === "application/pdf") {
      body.append("pdfimagedata", new Blob([image]));
      body.append("jsondata", JSON.stringify({ convertToImageTypes: ["PNG"] }));
    } else if (file[0].type === "image/png") {
      //dynamically set the imagedata based on the file type
      body.append(
        `${file[0].type.split("/")[1].toLowerCase()}imagedata`,
        new Blob([image])
      );
      body.append("jsondata", "");
    }

    await fetch(`${apiUrl}facilities/${facilityId}/images`, {
      method: "POST",
      headers: {
        "auth-token": token,
      },
      body,
    })
      .then((res) => res.json())
      .then((res = {}) => {
        const { facility = {} } = res;
        const { images = [] } = facility;
        const image = images.find((image) => image.imageType === "PNG") || {};
        const { imageId, url = "" } = image;
        handleSnackbar(
          <>
            "Facility Map Successfully Uploaded! <br /> Drop Marker on Map to
            Define (0,0) Point.",
          </>,
          false
        );
        setState((prevState) => ({
          ...prevState,
          facilityImage: url,
          facilityImageId: imageId,
          //set zero and far points to default
          zeroPoint: { disablePlunger: false, hasLoaded: true, x: 0, y: 0 },
          farPoint: { disablePlunger: false, hasLoaded: true, x: 0, y: 0 },
          loading: false,
        }));
        dispatchGlobal(
          updateFacilities({
            body: res.facility,
            facilityId: res.facility?.facilityId,
          })
        );
        return res;
      });
  };

  reader.readAsArrayBuffer(file[0]);
};
