import React from "react";
import { Box, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

function TabPanel(props) {
  const { children, useBox = false, index, noAppBarColor, value, ...other } = props;
  const item = useBox ? children : <Box p={3}>{children}</Box>;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && item}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HorizontalTabs(props) {
  const { isSnapshotPage, noAppBarColor } = props;
  const theme = useTheme()
  const { palette = {} } = theme
  const { success = {} } = palette

  const classes = {
    appBar: {
      // Tab styling overrides this
      backgroundColor: `${palette.primary.main}`,
      // color: "white"
    },
    noAppBarBackground: {
      backgroundColor: "transparent",
    },
    indicator: {
      backgroundColor: `${success?.main}`,
    },
    noIndicator: {
      backgroundColor: "transparent",
    },
    root: {
      flexGrow: 1,
    },
    selected: {
      color: "white",
      "&:focus": {
        // outline is same syntax as border, so you could do "2px solid #32355C" etc.
        outline: "none",
      },
    },
    stickyHeaderBar: {
      backgroundColor: "#E6E7E8",
    }
  }
  const [value, setValue] = React.useState(0);
  const {
    customControl,
    customValue,
    fullWidth = true,
    // fullWidth = false,
    headerStyle,
    position = "static",
    useBox,
    useIndicator = true,
  } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const headerContent = (
    <Tabs
      className={headerStyle ? headerStyle : ""}
      value={customValue ? customValue : value}
      onChange={customControl ? customControl : handleChange}
      sx={{
        indicator: useIndicator ? classes.indicator : classes.noIndicator,
      }}
      TabIndicatorProps={{
        style: {
          backgroundColor: `${success?.main}`,
        },
      }}
    >
      {props.children
        .filter((element) => element !== null)
        .map((child, idx) => (
          <Tab
            {...a11yProps(idx)}
            sx={{
              color: noAppBarColor === undefined ? "white !important" : null,
              //if idx is same as value, then set background color to light green
              backgroundColor: idx === value && noAppBarColor === undefined ? `${success?.main}` : null,
              //set right border of tab to 1px solid white if isSnapshotPage is true
              borderRight: isSnapshotPage ? "1px solid white" : null,
            }}

            icon={child.props.icon ? child.props.icon : null}
            key={idx}
            label={child.props.label}
            onClick={child.props.onClick}
          />
        ))}
    </Tabs>
  );

  const header =
    fullWidth && position === "sticky" ? (
      <AppBar
        position={position}
        sx={classes.stickyHeaderBar}
        elevation={props.noElevation ? 0 : 1}
        xs={12}
      >
        {headerContent}
      </AppBar>
    ) : fullWidth ? (
      <AppBar
        position={position}
        sx={!props.noAppBarColor ? classes.appBar : classes.noAppBarBackground}
        elevation={props.noElevation ? 0 : 1}
      >
        {headerContent}
      </AppBar>
    ) : (
      headerContent
    );

  return (
    <div style={{  flexGrow: 1 }}>
      {header}
      {props.children
        .filter((element) => element !== null)
        .map((child, idx) => (
          <TabPanel
            index={idx}
            key={idx}
            useBox={useBox}
            value={customValue ? customValue : value}
          >
            {child}
          </TabPanel>
        ))}
    </div>
  );
}
