import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import { ReactComponent as AsteriskSolidIcon } from "../../../assets/faIcons/asterisk-solid.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiSVGIcon from "../../IconPicker/MuiSVGIcon";


//TODO - Select needs a "Select All". Currently the only instance of a select all we need is with
// batch assets. But since no one is using batch's this is not important. Below is a snippet from
// the old select all code. It is not complete and will not work. But it is a good starting point

// Another function the old react-select search had was the ability to search for items. We need to figure this out with MUI
// Looks like we have MUI autocomplete. We even have a component for this. We need to style the
// Component to look like this one at some point. But for now, we can use this one.

// const handleItemSelectChange = (event, actionMeta) => {
//   const { option = {}, action = "" } = actionMeta;
//   const { value = "" } = option;

//   if (
//     updateEvent.assets?.some((item) => item.value === "<SELECT_ALL>") &&
//     action === "deselect-option"
//   ) {
//     // If select all is enabled and user then de-selects an item, we remove select all from the selected items
//     setUpdateEvent((prevState) => ({
//       ...prevState,
//       assets: [...event?.filter((item) => item.value !== "<SELECT_ALL>")],
//     }));
//   } else if (
//     value === "<SELECT_ALL>" &&
//     updateEvent.assets?.some((item) => item.value === "<SELECT_ALL>")
//   ) {
//     // If user has select all enabled, and then clicks it again. We deselect all assets
//     setUpdateEvent((prevState) => ({ ...prevState, assets: [] }));
//   } else if (value === "<SELECT_ALL>") {
//     // If user clicks select all, we add all assets
//     setUpdateEvent((prevState) => ({
//       ...prevState,
//       assets: itemOptions,
//     }));
//   } else {
//     setUpdateEvent((prevState) => ({ ...prevState, assets: event }));
//   }
// };

function SimpleSelect(props) {
  const {
    disabled,
    error,
    errorText = "Required Field",
    helperText,
    id,
    inputProps,
    label,
    labelPlacement = "top",
    labelStyles = {},
    margin,
    multiple = false,
    onChange,
    onClear,
    onChipDelete = () => { },
    onDeleteIconClick = () => { },
    options,

    // If present a placeholder will be added as an option with the value of ""
    placeHolderText,
    readOnly,
    required,
    showDeleteIcon = false,
    size = "small",
    style,
    type,
    value = multiple ? [] : "",
    variant,
  } = props;
  const theme = useTheme()
  const { palette = {} } = theme
  const { typography = {} } = palette
  const formattedLabel = label?.replace(/\s+/g, "-");

  let labelWithAstrisk;
  if (required) {
    labelWithAstrisk = (
      <Box sx={{ display: "flex" }}>
        {label}
        <MuiSVGIcon
          icon={<AsteriskSolidIcon/>}
          sx={{
            color: "red",
            fontSize: "10px",
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={
        labelPlacement === "start"
          ? {
            display: "flex",
            alignItems: "center",
          }
          : {}
      }
    >
      {label ? (
        <Box
          sx={{
            color: typography?.secondary,
            display: "flex",
            fontFamily: "Lato",
            fontWeight: "bold !important",
            justifyContent: "space-between",
            lineHeight: 1,
            marginBottom: `${labelPlacement === "start" ? "inherit" : ".5rem"}`,
            marginTop: `${labelPlacement === "start" ? "inherit" : ".2rem"}`,
            marginRight: `${labelPlacement === "start" ? ".5rem" : ""}`,
            ...labelStyles,
          }}
        >
          <InputLabel
            id={formattedLabel}
            shrink={props.shrink}
            sx={{
              color: typography?.secondary,
              fontFamily: "Lato",
              fontWeight: "bold",
            }}
          >
            {required ? labelWithAstrisk : label ? label : "Select"}
          </InputLabel>
          {showDeleteIcon ? (
            <IconButton
              aria-label={`Delete ${label}`}
              onClick={() => onDeleteIconClick()}
            >
              <DeleteIcon
                fontSize="small"
                cursor="pointer"
              />
            </IconButton>
          ) : null}
          {onClear ? (
            <Typography
              className={value?.length > 0 ? "pointer" : ""}
              onClick={onClear}
              //if user hits enter or space, invoke the onClear function
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  onClear();
                }
              }}
              role="button"
              sx={{
                cursor: value.length > 0 ? "pointer" : "not-allowed",
                textDecoration: "underline"
              }}
              tabIndex={0}
              variant="caption"
            >
              Clear
            </Typography>
          ) : null}
        </Box>
      ) : null}
      <FormControl
        className="simple-select"
        error={error}
        size={size}
        sx={{ width: "100%" }}
        variant={variant ? variant : "standard"}
      >
        <Select
          disabled={disabled}
          id={id}
          inputProps={{ readOnly: readOnly, ...inputProps }}
          data-cypress-id={inputProps?.["data-cypress-id"]}
          displayEmpty={placeHolderText ? true : false}
          labelId={formattedLabel}
          margin={margin}
          MenuProps={{ disablePortal: true }}
          multiple={multiple}
          onChange={(e) => {
            onChange(e);
          }}
          renderValue={
            multiple
              ? (selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      deleteIcon={
                        <CancelIcon
                          // Stops the select menu from activating when they click on the cancel icon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      key={value}
                      // Label is stolen from https://stackoverflow.com/questions/73998290/material-ui-how-to-rendervalue-display-label-of-select-tag
                      // The lack of control with this prop is funny
                      label={
                        options.find((item) => item.value === value)?.label
                      }
                      onDelete={() => onChipDelete(value)}
                      size="small"
                      sx={{ borderRadius: "4px" }}
                    />
                  ))}
                </Box>
              )
              : null
          }
          required={required}
          style={style}
          type={type}
          value={value}
        >
          {placeHolderText ? (
            <MenuItem disabled value="">
              {placeHolderText}
            </MenuItem>
          ) : null}
          {options && options.length ? (
            options.map((option, idx) => (
              <MenuItem
                disabled={option.disabled}
                value={option.value}
                key={`${idx} - ${option.value}`}
              >
                {option.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem>None</MenuItem>
          )}
        </Select>
        {error ? <FormHelperText>{errorText}</FormHelperText> : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </Box>
  );
}

export default SimpleSelect;
