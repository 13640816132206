import { createAsyncThunk } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

export const initInventoryHistory = createAsyncThunk(
  "inventoryHistory/init",
  async (_, thunkAPI) => {
    const { getState, extra: apiService, dispatch } = thunkAPI;
    // expected user to be initialized before initializing organization
    const {
      token = "",
      organizationId = "",
      userPropertiesMap = {},
    } = getState().user;

    const { classifications } = getState().organization;

    const { timeZone = {} } = userPropertiesMap.propertiesMap;

    const { inventoryHistoryTableSettings = {} } = userPropertiesMap?.propertiesMap;

    // initial filters come from user properties if present, or are initialized here.
    // The "limit", "start", and "sort" filters are controlled from the "rows", "page" index, and column sorting headers on the table itself, not the filter drop down
    const initFilters = {
      assetCategories: [],
      assetId: null,
      assetIds: [],
      assetType: null,
      defaultColumnOrder: [],
      endDate: null,
      events: [],
      hideScans: true,
      limit: 25,
      locale: null,
      locals: [],
      locations: [],
      pca:false,
      sorted: [
        {
          id: "timeOfLog",
          desc: true,
        },
      ],
      start: 0,
      startDate: null,
      tz: timeZone,
      users: [],
      zones: null,
      ...(inventoryHistoryTableSettings || {}),
    };

    const data = await apiService.inventoryHistory
      .searchHistories(
        { token, organizationId, classifications },
        inventoryHistoryTableSettings
      )
      .then(async (data) => {
        if (data.error) {
          return dispatch({
            type: "alert/showAlert",
            payload: { type: "error", text: data.error },
          });
        } else if (data.success) {
          return {
            success: true,
            filters: initFilters,
            histories: data.assetHistories,
            count: data.count,
            showInstructions: isEmpty(inventoryHistoryTableSettings),
          };
        } else {
          return dispatch({
            type: "alert/showAlert",
            payload: { type: "error", text: data.error },
          });
        }
      });

    // data.reduxRequestId = thunkAPI.requestId;
    return data;
  }
);
