import { AppBar, Box, Toolbar, Grid } from "@mui/material";
import AssetSelector from "../../Selects/AssetSelector/AssetSelector";

export default function TopNav(props) {
  return (
    <Box>
      <AppBar
        className="danfoo"
        // position="static"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
        }}
      >
        <Grid
          container
          className="danfoo"
          sx={{
            justifyContent: "center",
          }}
        >
          <Grid item xs={6}>
            <Toolbar>
              <AssetSelector {...props} />
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
    </Box>
  );
}
