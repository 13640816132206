import { useEffect, useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { getDevices } from "../../../utils/API/Devices/Devices";
import { Pie } from "react-chartjs-2";

export default function TrackerCount(props) {
  const [state, setState] = useState({
    assigned: 0,
    unAssigned: 0,
  });
  const { assigned, unAssigned } = state;

  // Testing variable
  const data = {
    labels: ["Assigned", "Unassigned"],
    datasets: [
      {
        label: "# of Votes",
        data: [assigned, unAssigned],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  useEffect(() => {
    getDevices(props).then((data) => {
      let assigned = 0;
      let unAssigned = 0;

      data.assets.forEach((asset) => {
        const { device = {} } = asset || {};
        const { hasDescendents = false } = device;

        if (hasDescendents) {
          assigned++;
        } else {
          unAssigned++;
        }

        setState({
          assigned,
          unAssigned,
        });
      });
    });
  }, [props]);

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item xs={12} className="danfoo2">
        <Card sx={{ boxShadow: 3, height: "100%" }}>
          <>
            <CardContent
              sx={{
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Grid container sx={{}}>
                {/* Title */}
                <Grid item xs={12} sx={{marginBottom:'1rem'}}>
                  <Typography variant="h5">Tracker Count</Typography>
                </Grid>

                {/* Pie Chart */}
                <Grid item xs={12}>
                  <Pie
                    data={data}
                    options={options}
                    style={{ maxHeight: "50vh" }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </>
        </Card>
      </Grid>
    </Grid>
  );
}
