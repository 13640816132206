import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";

export default function DeleteTemplateDialog(props) {

  const classes = {
    buttonContainer: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      margin: "1rem 0",
    },
  }

  const { open, modalShow, template, onCancel, onSubmit } = props;
  return (
    <Dialog
      open={open}
      onClose={modalShow}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {`Are you sure you want to delete ${template.label}? This action cannot
            be undone`}
          </Grid>
          <div sx={classes.buttonContainer}>
            <Grid item xs={5}>
              <MaterialUiButton
                color="primary"
                content="Yes"
                fullWidth={true}
                onClick={() => {
                  onSubmit(template.value);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <MaterialUiButton
                color="secondary"
                content="No"
                fullWidth={true}
                label="No"
                onClick={() => {
                  onCancel();
                }}
              />
            </Grid>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
