import ActionButtons from "../../../../components/Buttons/ActionButtons";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';import MaterialUiDataGridPremium from "../../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";
import WarehouseIcon from '@mui/icons-material/Warehouse';import {
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { ReactComponent as ObjectGroupSolidIcon } from "../../../../assets/faIcons/object-group-solid.svg";

export default function FacilityTable(props) {
  const {
    dialog,
    permissions,
    setDialog,
    setRedirect,
    setView,
    state = {},
    toolBarButtons,
  } = props;
  const { facilities } = state
  const { editLocation, deleteLocation } = permissions;

  //sort facilities by name
  const sortedFacilities = [...facilities].sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA.localeCompare(nameB);
  });



  const tableColumns = [
    {
      field: "name",
      flex: 2,
      headerName: "Facility Name",
      id: "name",
      renderCell: (data) => {
        const { row } = data;
        if(data.rowNode.type === "group") {
          return data.value;
        }
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {row.name}
          </div>
        );
      },
      valueGetter: (value, row) => row?.name || '',
      groupingValueGetter: (value, row) => row?.name || null,
    },
    {
      field: "facilityType",
      flex: 1,
      headerName: "Facility Type",
      id: "facilityType",
      renderCell: (data) => {
        const { row } = data;
        if(data.rowNode.type === "group") {
          return data.value;
        }
        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {row.facilityType}
          </div>
        );
      },
      valueGetter: (value, row) => row?.facilityType || '',
      groupingValueGetter: (value, row) => row?.facilityType || null,
    },
    {
      field: "city",
      flex: 1,
      headerName: "City",
      id: "city",
      renderCell: (data) => {
        const { row } = data;
        const { location = {} } = row;
        const { city = "" } = location;
        if(data.rowNode.type === "group") {
          return data.value;
        }

        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {city}
          </div>
        );
      },
      valueGetter: (value, row) => row?.location?.city || "", // required for nested values
      groupingValueGetter: (value, row) => row?.location?.city || null,
    },
    {
      field: "state",
      flex: 1,
      headerName: "State",
      id: "state",
      renderCell: (data) => {
        const { row } = data;
        const { location = {} } = row;
        const { state = "" } = location;
        if(data.rowNode.type === "group") {
          return data.value;
        }

        return (
          <div
            className="pointer"
            onClick={() => {
              setDialog({
                ...dialog,
                dialogShow: true,
                selectedLocation: row,
                dialogTitle: "Facility Detail",
              });
            }}
          >
            {state}
          </div>
        );
      },
      valueGetter: (value, row) => row?.location?.state || "",
      groupingValueGetter: (value, row) => row?.location?.state || null,
    },
    {
      disableColumnMenu: true,
      disableSortBy: true,
      field: "Actions",
      filterable: false,
      flex: 2,
      groupable: false,
      headerName: "Actions",
      id: "facilityId",
      renderCell: (data) => {
        const { row } = data;
        if(data.rowNode.type === "group") return null;
        const buttonIcons = [];
        [
          "Zones",
          "Facility Layout",
          "Facility Area",
          "Edit Facilities",
          "Delete Facilities",
        ].forEach((element) => {
          switch (element) {
            case "Zones":
              if (editLocation) {
                buttonIcons.push({
                  icon: <ObjectGroupSolidIcon />,
                  title: "Facility Zones",
                  action: () => {
                    setView({
                      facility: row,
                      viewZones: true,
                    });
                  },
                });
              }
              break;
            case "Facility Layout":
              buttonIcons.push({
                icon: <WarehouseIcon/>,
                title: "Facility Layout",
                action: () => {
                  setRedirect({
                    isRedirect: true,
                    selectedFacility: row,
                  });
                },
              });
              break;
            case "Edit Facilities":
              if (editLocation) {
                buttonIcons.push({
                  icon: <EditIcon/>,
                  title: "Edit",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedLocation: row,
                      dialogTitle: "Edit Facility",
                    });
                  },
                });
              }
              break;
            case "Delete Facilities":
              if (deleteLocation) {
                buttonIcons.push({
                  icon: <DeleteIcon/>,
                  title: "Delete",
                  action: () => {
                    setDialog({
                      ...dialog,
                      dialogShow: true,
                      selectedLocation: row,
                      dialogTitle: "Delete Facility",
                    });
                  },
                });
              }
              break;
            default:
              break;
          }
        });
        return <ActionButtons content={buttonIcons} />;
      },
      sortable: false,
    },
  ];

  return (
    <MaterialUiDataGridPremium
      checkboxSelection={false}
      columns={tableColumns}
      components={GridToolbar}
      exportFileName={"Facilities"}
      getRowId={(row) => row.facilityId}
      loading={false}
      needsCustomToolbar={true}
      rowCount={facilities.length}
      rows={sortedFacilities}
      toolBarButtons={toolBarButtons}
    />
  );
}
