import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Grid, Typography, useTheme } from "@mui/material";
import ImageModal from "./ImageModal";
import MaterialUiButton from "../Buttons/MaterialUiButton/MaterialUiButton";
import SimpleTextField from "../Forms/FieldTypes/TextField";

export default function ImagesDisplayNSave(props) {
  const theme = useTheme()
  const { palette } = theme
  const { typography = {} } = palette
  const classes = {
    button: {
    },
    buttonContainer: {
      display: "flex",
      gap: "2%",
      height: "40px",
    },
    imageContainer: {
      alignItems: "center",
      display: "flex",
      marginTop: '1.35rem',
      padding: "0 1rem",
    },
    files: {
      margin: "1rem 0",
    },
    list: {
      padding: "0",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      margin: "1rem 0",
      //create border and give area within border an elevated look
      border: "1px solid grey",
      borderRadius: "5px",
      boxShadow: "0 0 5px 2px rgba(0,0,0,0.5)",
      padding: ".5rem",
      alignItems: "center",
      flexWrap: "wrap",
      width: "100%",
    },
    text: {
      color: typography?.light,
      fontFamily: "Lato Regular",
      margin: '1rem !important'
    },
    textAndButtonContainer: {
      alignItems: "end",
      display: "flex",
      gap: "10px",
      width: "100%",
    }
  }

  const {
    acceptedFileTypes = "application/pdf, image/jpeg, image/png, image/jpg",
    deleteImage,
    editImage,
    images = [],
    imagesToEdit,
    readOnly,
    setState
  } = props;


  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      const preview = URL.createObjectURL(file);
      const { name, type } = file;
      console.log("type: ", type)
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const image = reader.result;
        setState((prevState) => ({
          ...prevState,
          images: [
            ...prevState.images,
            { name: name, imageType: type, path: name, image, preview },
          ],
        }));
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  });

  const formattedAcceptedFileTypes = acceptedFileTypes.split(',').map((fileType) => {
    return fileType.split('/')[1].trim()
  }).join(', .')


  const files = images
    //sort so unsaved images are at the top. So images without an imageId are at the top
    // .sort((a, b) => {
    //   if(a.imageId && !b.imageId) {
    //     return 1
    //   }
    //   else if(!a.imageId && b.imageId) {
    //     return -1
    //   }
    //   else {
    //     return 0
    //   }
    // })
    .map((file, index) => {
      const { imageId, imageType, name, preview, url } = file;
      return (
        <Grid sx={{ ...classes.root, ...{ backgroundColor: imageId ? 'white' : 'lightgrey', borderRadius: '5px' } }} key={`${index}`}>
          <Typography component="p" sx={{ color: "red" }} >{imageId ? "" : "*Not Saved*"}</Typography>
          <Grid item sx={classes.textAndButtonContainer}>
            <Grid item xs={12}>
              <SimpleTextField
                // This always rings false as setCheckImages is never set
                // error={checkImages && name.length === 0}
                id="urlBase"
                label={"Attachment Name"}
                onChange={(event) => {
                  //in setState, update images in state so that the name is updated
                  //also, updateImagesToEdit to include the imageId and the new name
                  setState((prevState) => {
                    //find the image in state.images and update the name
                    const updatedImages = prevState.images.map((img) =>
                      img.imageId === imageId ? { ...img, name: event.target.value } : img
                    );

                    //find the image in state.imagesToEdit and update the name
                    const updatedImagesToEdit = { ...prevState.imagesToEdit, [imageId]: { ...prevState.imagesToEdit[imageId], name: event.target.value } };

                    return { ...prevState, images: updatedImages, imagesToEdit: updatedImagesToEdit };
                  });
                }}
                readOnly={readOnly}
                required
                value={name}
              />
            </Grid>
            <Grid item xs={8} sx={classes.buttonContainer}>
              <MaterialUiButton
                //pass in aria-label to MaterialUiButton. If its not a pdf file, then the aria-label will be the name of the image.
                //if its a pdf file, then the aria-label will be "PDF + name + opens in new tab"
                aria-label={imageType === "PDF" ? `PDF ${name} opens in new tab` : name}
                color="primary"
                fullWidth={true}
                label="View"
                onClick={() => {
                  //if is pdf, open pdf file in new tab
                  // if (name.split(".").pop() === "pdf") {
                  if (imageType === "PDF") {
                    window.open(url, "_blank");
                    return;
                  }
                  setSelectedImage({ preview: imageId ? url : preview, name });
                  setModalOpen(true);
                }}
                size="small"
              />
              {readOnly ? null : (
                <MaterialUiButton
                  color="delete"
                  fullWidth={true}
                  label="Delete"
                  onClick={() => {
                    if (deleteImage) {
                      deleteImage(imageId)
                    }
                    else {
                      setState((prevState) => {
                        const newImagesArray = [...prevState.images];

                        // We grab the item we want to delete and throw it into the "imagesToDelete" array.
                        // Since we delete, only when the Submit button is clicked
                        let itemForDeletion = {};

                        itemForDeletion = newImagesArray[index];
                        newImagesArray.splice(index, 1);
                        return {
                          ...prevState,
                          images: newImagesArray,
                          imagesToDelete: [
                            ...prevState.imagesToDelete,
                            itemForDeletion,
                          ],
                        };
                      });
                    }
                  }}
                  size="small"
                />
              )}
              {/* should only be available on unsaved images and page which does save individually and not by the group */}
              {/* pages that do the save individually use the editImage function */}
              {imageId && editImage && (
                <MaterialUiButton
                  color="primary"
                  //if imageId is found in state.imagesToEdit, then the name has been changed and button is to be enabled
                  disabled={
                    !Object.keys(imagesToEdit)?.includes(imageId.toString())
                  }
                  fullWidth={true}
                  label="Save Edit"
                  onClick={() => {
                    editImage(imageId)
                  }}
                  size="small"
                  sx={{ lineHeight: '1.0rem' }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    });

  return (
    <>
      <ImageModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        image={selectedImage}
      />
      <Grid item xs={12}>
        <section className="container">
          {readOnly ? null : (
            <div {...getRootProps({ className: "dropzone pointer" })}>
              <input {...getInputProps()} />
              <p sx={classes.text}>
                Drag and drop or click to select files.
                Accepted file types: {formattedAcceptedFileTypes}
              </p>
            </div>
          )}
          {images.length > 0 ? (
            <aside sx={classes.files}>
              <Typography component="h2" variant="h5">Attachments</Typography>
              <ul sx={classes.list}>{files}</ul>
            </aside>
          ) : null}
        </section>
      </Grid>
    </>
  );
}
