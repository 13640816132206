import { createSlice } from "@reduxjs/toolkit";
import { checkSession, onLogin, updateWidgetSettingsThunk } from "./thunks";
import * as userSelectors from "./selectors";
import { extraReducers } from "./extraReducers";

const initialState = {
  checkSession: {
    status: "idle",
    sessionValid: true,
  },
  token: localStorage.getItem("token"),
  appUserId: "",
  organizationId: "",
  firstName: "",
  lastName: "",
  email: "",
  // e.g., Asset, Asset / Product, Product
  appUserType: "",
  // e.g., admin, editor, etc.
  usersConsoleRole: "",
  // User actions, e.g., available actions, edit asset, delete batch, edit facility, etc.
  userActions: [],
  // e.g., [mobile, console, emailOptOut]
  userPermissions: [],
  // e.g., what pages the user can see
  viewPermissions: {
    showAssetHistory: true,
    showBatches: true,
    showDevices: true,
    showFacilities: true,
    showInventory: true,
    showOrganizations: true,
    showProducts: true,
    showSettings: true,
    showUsers: true,
  },
  // this will be where all the user's preferences, e.g., table and filter settings, will be kept
  userPropertiesMap: {
    propertiesMap: {
      assetHistoryTableSettings: {},
      assetSnapshotSettings: {},
      assetStatusTableSettings: {},
      batchHistoryTableSettings: {},
      consoleSettings: {},
      deviceTableSettings: {},
      timeZone: {},
      widgetSettings: {},
    },
  },
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
    // update from settings/user page
    updateUserSettings: (state, action) => {
      state.userPropertiesMap.propertiesMap = action.payload.propertiesMap;
      state.email = action.payload.email;
    },
    // update widgets from dashboard
    updateWidgetSettings: (state, action) => {
      // object path may be undefined
      state.userPropertiesMap.propertiesMap.widgetSettings =
        state.userPropertiesMap.propertiesMap.widgetSettings || {};
      // spreading settings so we can e.g. update one property at a time without overwriting all properties
      const widgetSettingsFromStore =
        state.userPropertiesMap.propertiesMap.widgetSettings[
          action.payload.widget
        ] || {};

      const spreadSettings = {
        ...widgetSettingsFromStore,
        ...action.payload.settings,
      };

      state.userPropertiesMap.propertiesMap.widgetSettings[
        action.payload.widget
      ] = spreadSettings;
    },
    updateUserPropertiesMap: (state, action) => {
      state.userPropertiesMap.propertiesMap = {
        ...state.userPropertiesMap.propertiesMap,
        ...action.payload,
      };
    },

    onLogout: (state, action) => {
      localStorage.clear();
      return {
        ...initialState,
        checkSession: {
          status: "idle",
          sessionValid: false,
        },
      };
    },
  },
  extraReducers: {
    // look for async thunk states, conditionally update state based on thunk state
    ...extraReducers,
  },
});

// Action creators are generated for each case reducer function
const {
  updateUserSettings,
  updateWidgetSettings,
  updateUserPreferences,
  updateUserPropertiesMap,
  updateUserPermissions,
  onLogout,
} = userSlice.actions;

export {
  updateUserPermissions,
  checkSession,
  onLogin,
  onLogout,
  updateUserPreferences,
  updateUserSettings,
  updateWidgetSettings,
  updateWidgetSettingsThunk,
  updateUserPropertiesMap,
  userSelectors,
};

// export the default reducer to be used in the store configuration
export default userSlice.reducer;
